<template>
    
    <div class="SelfLoading" v-show="msg.show">
          <div class="box_bg">
            <div  class="fulfilling-bouncing-circle-spinner">
            <div class="circle"></div>
            <div class="orbit">
                <img src="@/assets/img/index/logo.png" alt="">
            </div>
          </div>
          <p class="loading_text">{{ msg.title }}</p>
          </div>
    </div>
  </template>
   
  <script>
  export default {
    name: 'SelfLoading',
   
    props: {
      msg: {
        type: Object,
        default: () => ({
          show: false,
          title: '加载中...'
        })
      }
   
    },
   
    methods: {
      // 显示loading的方法
      show (title = '加载中...') {
        this.msg.show = true
        this.msg.title = title
      },
   
      // 隐藏loading的方法
      hide () {
        this.msg.show = false
      }
    }
  }
  </script>
   
  <style lang="less" scoped>
    .SelfLoading{
      width: 1200px;
      height: 420px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      position: relative;
      .box_bg{
        width: 130px;
        height: 130px;
        background-color: #fff;
        box-shadow: 0 0 6px 4px #dfdede;
        border-radius: 8px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;

      }
      .loading_text{
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
      }
      .fulfilling-bouncing-circle-spinner, .fulfilling-bouncing-circle-spinner * {
        box-sizing: border-box;
      }
  
      .fulfilling-bouncing-circle-spinner {
        height: 60px;
        width: 60px;
        position: relative;
        position: absolute;
    margin: auto auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
        animation: fulfilling-bouncing-circle-spinner-animation infinite 4000ms ease;
      }
  
      .fulfilling-bouncing-circle-spinner .orbit {
        height: 60px;
        width: 60px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        border: calc(60px * 0.03) solid #ff1d5e;
        animation: fulfilling-bouncing-circle-spinner-orbit-animation infinite 4000ms ease;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .fulfilling-bouncing-circle-spinner .orbit img{
          width: 70%;
          height: 70%;
          border-radius: 50%;
      }
  
      .fulfilling-bouncing-circle-spinner .circle {
        height: 60px;
        width: 60px;
        color: #ff1d5e;
        display: block;
        border-radius: 50%;
        position: relative;
        border: calc(60px * 0.1) solid #ff1d5e;
        animation: fulfilling-bouncing-circle-spinner-circle-animation infinite 4000ms ease;
        transform: rotate(0deg) scale(1);
      }
  
      @keyframes fulfilling-bouncing-circle-spinner-animation {
        0% {
          transform: rotate(0deg);
        }
  
        100% {
          transform: rotate(360deg);
        }
      }
  
      @keyframes fulfilling-bouncing-circle-spinner-orbit-animation {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1);
        }
        62.5% {
          transform: scale(0.8);
        }
        75% {
          transform: scale(1);
        }
        87.5% {
          transform: scale(0.8);
        }
        100% {
          transform: scale(1);
        }
      }
  
      @keyframes fulfilling-bouncing-circle-spinner-circle-animation {
        0% {
          transform: scale(1);
          border-color: transparent;
          border-top-color: inherit;
        }
        16.7% {
            transform: scale(1.3);
          border-color: transparent;
          border-top-color: initial;
          border-right-color: initial;
        }
        33.4% {
            transform: scale(1.4);
          border-color: transparent;
          border-top-color: inherit;
          border-right-color: inherit;
          border-bottom-color: inherit;
        }
        50% {
          border-color: inherit;
          transform: scale(1);
        }
        62.5% {
          border-color: inherit;
          transform: scale(1.4);
        }
        75% {
          border-color: inherit;
          transform: scale(1);
          opacity: 1;
        }
        87.5% {
          border-color: inherit;
          transform: scale(1.4);
        }
        100% {
          border-color: transparent;
          border-top-color: inherit;
          transform: scale(1);
        }
      }
    }
  </style>