<template>
  <div>
    <el-table :data="data" style="margin-top: 30px" :cell-style="tableRowStyle">
      <el-table-column prop="date" label="商品信息" align="center" width="280">
        <template slot-scope="scope">
          <div class="flex_row_aic">
            <div class="kegou" v-if="scope.row.show_red">
              <img src="@/assets/img/goods/bg.png" alt="" />
              <p>{{ scope.row.show_red }}</p>
            </div>
            <img @click="toDetail(scope.row)" style="width: 87px; height: 87px" :src="scope.row.logo" alt="" />
            <div class="info">
              <p class="shenglue_2 bold goods-title" @click="toDetail(scope.row)">{{ scope.row.title }}</p>
              <p class="c6">{{ scope.row.sku }}</p>
              <p class="c6">{{ scope.row.scqy }}</p>
              <!-- <div class="flex_row">
                <div v-for="sub in scope.row.tags" :key="sub.title" :style="`color: #${sub.color}; border: 1px solid #${sub.color};`" class="tag mr_10">
                  {{ sub.title }}
                </div>
              </div> -->
              <tagcom :tags="scope.row.tags"></tagcom>
              <!-- <p class="c6">{{ scope.row.show_red }}</p> -->
              <p class="c6">{{ scope.row.show_memo }}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="厂家" align="center" width="180">
        <template slot-scope="scope">
          <span class="shenglue_1">{{ scope.row.scqy }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="sku" label="规格" align="center" /> -->
      <el-table-column prop="jzl" label="件装量" align="center" />
      <el-table-column prop="zbz" label="中包装" align="center" />
      <el-table-column prop="validity_date" label="效期" align="center" />
      <el-table-column prop="kc_str" label="库存" align="center" />

      <el-table-column label="会员价" align="center" width="180">
        <template slot-scope="scope">
          <div style="display: flex; align-items: flex-end; justify-content: center">
            <span class="shenglue_1" style="color: red">￥{{ scope.row.price }}</span>
            <span v-if="scope.row.line_price" class="shenglue_1" style="color: #999; font-size: 12px; text-decoration: line-through">￥{{ scope.row.line_price }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="数量" align="center" width="150">
        <template slot-scope="{ row }">
          <el-input-number
            size="mini"
            :disabled="row.show_cart == 102"
            :min="Number(row.min_num)"
            :max="Number(row.max_num)"
            :step="Number(row.step)"
            v-model="row.number"
            @blur="blur(row)"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <img v-if="row.show_cart == 101" class="mr_10" style="width: 22px; height: 22px; cursor: pointer" src="@/assets/img/goods/cart.png" alt="" @click="handleAdd(row)" />
          <img v-if="row.is_collected == 102" style="width: 22px; height: 22px; cursor: pointer" src="@/assets/img/goods/heart.png" alt="" @click="handleCollect(row)" />
          <img v-if="row.is_collected == 101" style="width: 22px; height: 22px; cursor: pointer" src="@/assets/img/goods/heart_sel.png" alt="" @click="cancleCollect(row)" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import tagcom from "@/components/tag.vue";
export default {
  components: {
    tagcom,
  },
  data() {
    return {
      listData: [],
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    tableRowStyle({ columnIndex }) {
      if (columnIndex == 7) {
        return "color:#F93232;!important;";
      }
    },
    handleAdd(row) {
      let { show_cart, id, number } = row;

      if (show_cart == 102) return;
      // if (can_buy == 102) {
      //   this.$message.error(can_buy_str);
      //   return;
      // }

      this.$api("goods.addCart", { id, number: number }).then(() => {
        this.$store.dispatch("getBadge");
      });
    },
    handleChange(currentValue, item) {
      console.log(currentValue, item);
      // let number = item.number;
      // if (item.step != 1) {
      //   let num = Number(item.number) / Number(item.step);

      //   if (num % 1 !== 0) {
      //     number = Number(item.step) * (parseInt(num) + 1);
      //   }
      // }
    },
    blur(item) {
      if (item.step != 1) {
        let num = Number(item.number) / Number(item.step);

        if (num % 1 !== 0) {
          item.number = Number(item.step) * (parseInt(num) + 1);
        }
        this.listData.forEach((e) => {
          if (e.id == item.id) e = item;
        });
      }
    },
    handleCollect(row) {
      this.$api("goods.addCollect", { id: row.id }).then(() => {
        this.$message.success("收藏成功");
        this.data.forEach((v) => {
          if (row.id == v.id) {
            v.is_collected = 101;
          }
        });
      });
    },
    cancleCollect(row) {
      this.$api("account.delCollect", { id: row.id }).then(() => {
        this.$message.success("取消收藏成功");
        this.data.forEach((v) => {
          if (row.id == v.id) {
            v.is_collected = 102;
          }
        });
      });
    },
    toDetail(data) {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id: data.id },
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  text-align: left;
  margin-left: 10px;
  .goods-title {
    cursor: pointer;
  }
  .jx_img {
    width: 52px;
    height: 17px;
    margin-bottom: 8px;
    position: relative;
    img {
      position: absolute;
    }
    p {
      position: absolute;
      top: -2px;
      left: 5px;
      font-size: 12px;
      font-weight: 400;
      color: #fefefe;
      text-align: center;
    }
    i {
      position: absolute;
      color: #fefefe;
      right: 10px;
      top: 3px;
    }
  }
  .tag {
    padding: 0 5px;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
  }
  .c6 {
    font-size: 12px;
  }
}
.el-input-number--mini {
  width: 100px;
}
.kegou {
  top: 0;
  left: 0;
  position: absolute;
  width: 86px;
  height: 30px;
  p {
    font-size: 12px;
    position: absolute;
    top: 6px;
    left: 10px;
  }
}
</style>
