<template>
  <div class="allGoods_container">
    <div class="content">
      <el-breadcrumb v-if="pageName.length > 1" separator="/" class="mt_20 branditem">
        <el-breadcrumb-item>{{ pageName[0] }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ pageName[1] }}</el-breadcrumb-item>
      </el-breadcrumb>
      <category v-if="show" @sendType="sendType" @sendSearch="sendSearch" :total="total" />
      <el-breadcrumb v-if="!show" separator="/" class="mt_20 branditem">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>品牌专区</el-breadcrumb-item>
        <!-- <el-breadcrumb-item>{{ pname }}</el-breadcrumb-item> -->
      </el-breadcrumb>

      <div class="bigimg_main" v-if="type == 101">
        <div class="item" v-for="item in list" :key="item.id">
          <bigimg :itemData="item" :key="item.id" />
        </div>
        
        <self-loading ref="loadingRef" />
        <div class="empty" v-if="!list.length">
          <el-empty  :image-size="200"></el-empty>
        </div>
      </div>
      <div class="list_main" v-if="type == 102">
        <list :data="list" />
        <self-loading ref="loadingRef" />
      </div>
      <pagination :total="total" :currentPage="page" :pageSize="page_size" :pageSizes="[20, 40, 80, 120, 200]" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
    </div>
  </div>
</template>

<script>
import category from "@/components/category.vue";
import bigimg from "@/components/goodsItem/bigImg.vue";
import list from "@/components/goodsItem/list.vue";
import pagination from "@/components/pagination.vue";
export default {
  components: {
    category,
    bigimg,
    list,
    pagination,
  },
  data() {
    return {
      type: 101,
      list: [],
      topSearch: {}, //顶部搜索条件
      searchData: {}, //筛选条件
      page: 1,
      page_size: 20,
      total: 0,
      pageName: this.$store.state.pageName,
      area_id: "", //跳转所带参数
      cate_id: "", //商品分类id
      show: true,
      pname: "",
    };
  },
  watch: {
    $route(to) {
      let param = to.params;
      this.pageName = this.$store.state.pageName;
      if (JSON.stringify(param) !== "{}" && !param.show) {
        // console.log("--------------------------------------------111");
        this.show = false;
        this.pname = param.pname;
        let p = {
          brand_id: param.brand_id,
          cate_id: param.cate_id,
        };
        this.getGoods(p);
      } else {
        // console.log("--------------------------------------------222");
        let value = to.query.value;
        if (value) {
          let arr = value.split("&");
          let filterArr = arr.filter((v) => {
            return v.includes("area_id");
          });
          if (filterArr.length > 0) {
            let area_id_str = filterArr[0].substr(8, filterArr[0].length);
            this.area_id = area_id_str;
          } else {
            this.area_id = "";
          }
        }
        if (to.query.cate_id) {
          this.cate_id = to.query.cate_id;
        }
        let p = {
          area_id: this.area_id,
          cate_id: this.cate_id,
          ...this.$store.getters.searchKey,
          order_key: 101, //默认综合排序 升序
          order_desc: 101,
        };
        this.getGoods(p);
      }
    },
  },
  created() {
    
  },
  mounted() {
    let param = this.$route.params;
    console.log(this.$route.query,'paesd');
    this.pageName = this.$store.state.pageName;
    // console.log("2222路由带的参数-----", this.$route.query);
    // console.log("1111路由带的参数-----", param);
    if (JSON.stringify(param) !== "{}" && !param.show) {
      // console.log("------1111");
      this.show = false;
      this.pname = param.pname;
      let p = {
        brand_id: param.brand_id,
        cate_id: param.cate_id,
      };
      this.getGoods(p);
    } else {
      if(this.$route.query.brand_id){
        this.show = false
      }
      let value = this.$route.query.value;
      let cate_id = this.$route.query.cate_id;
      let floor_id = this.$route.query.floor_id;
      let area_id = this.$route.query.area_id;
      let brand_id = this.$route.query.brand_id;
      let zone_id = this.$route.query.zone_id;
      let control_id = this.$route.query.control_id;

      // console.log("参数---", cate_id);
      if (value) {
        let arr = value.split("&");
        arr.forEach((v) => {
          let index = v.indexOf("area_id");
          if (index != -1) {
            let result = v.substr(index + 8, v.length);
            this.area_id = result;
          }
        });
      }
      // if (cate_id) {
      //   this.p.cate_id = cate_id;
      // }
      let p = {
        area_id: area_id,
        cate_id: cate_id,
        floor_id: floor_id,
        brand_id: brand_id,
        zone_id: zone_id,
        control_id: control_id,
        ...this.$store.getters.searchKey,
        order_key: 101, //默认综合排序 升序
        order_desc: 101,
      };
      this.$store.commit("SAVE_SEARCHDATA", p);
      // console.log("created---请求参数-----", p);
      this.getGoods(p);
    }
    this.$bus.$on("updateSearch", this.updateSearch);
  },
  methods: {
    getGoods(data) {
      this.list = []
      this.$refs.loadingRef.show()
      this.$api("goods.getGoods", {
        ...data,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        let listData = res.data.list.map((v) => {
          this.$set(v, "number", v.min_num);
          return v;
        });
        this.total = res.data.count;
        setTimeout(() => {
          this.list = listData
          this.$refs.loadingRef.hide()
        }, 500);
        
      });
    },
    updateSearch(e) {
      this.show = true;
      this.topSearch = e;
      this.page = 1;
      // console.log(this.topSearch, "顶部搜索条件");
      this.getGoods(e);
    },
    sendSearch(p) {
      this.searchData = {
        ...p,
        ...this.$store.getters.searchKey,
        scqy_ctr: p.scqy_key,
        sku_ctr: p.sku_key,
      };
      // console.log(this.searchData, "筛选条件");
      this.page = 1;
      this.getGoods({ ...this.searchData, area_id: this.area_id });
    },
    handleSizeChange(val) {
      this.page_size = val;
      if (this.$store.getters.searchKey.kw) {
        this.getGoods({ ...this.searchData, ...this.$store.getters.searchKey });
      } else {
        this.getGoods({ ...this.searchData, area_id: this.area_id });
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      if (this.$store.getters.searchKey.kw) {
        this.getGoods({ ...this.searchData, ...this.$store.getters.searchKey });
      } else {
        this.getGoods({ ...this.searchData, area_id: this.area_id });
      }
    },
    sendType(type) {
      this.type = type;
    },
  },
  destroyed() {
    this.$bus.$off("updateSearch", this.updateSearch);
  },
};
</script>

<style lang="less" scoped>
.branditem {
  padding-top: 20px;
}
.allGoods_container {
  background-color: #fff;
  .bigimg_main {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .item {
    margin-right: 12px;
  }
  .item:nth-child(5n) {
    margin-right: 0;
  }
  .list_main {
    margin-top: -10px;
  }
  .empty {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>
