<template>
  <div class="cart_container">
    <div class="content">
      <div class="top flex_sp_c">
        <div>
          <span class="c3">我的购物车</span>
          <span class="c9">
            （订单满
            <span style="color: #e91e10">{{ info.byje }}</span> 元包邮）
          </span>
        </div>
        <div class="flex_row c9 step">
          <div class="cart_item">
            <span class="color_theme">我的购物车</span>
            <div class="green">
              <span>1</span>
              <div class="line active_bg"></div>
            </div>
          </div>
          <div class="cart_item">
            <span>核对订单信息</span>
            <div class="grey">
              <div class="line1"></div>
              <span>2</span>
              <div class="line"></div>
            </div>
          </div>
          <div class="cart_item">
            <span>成功提交订单</span>
            <div class="grey">
              <div class="line1"></div>
              <span>3</span>
            </div>
          </div>
        </div>
      </div>
      <div class="filter-cart">
        <div class="serach_box">
          <el-input
            placeholder="请输入搜索内容 名称/厂家"
            clearable
            v-model="kw"
            @keyup.enter.native="getCartList"
            class="input-w"
          >
          </el-input>
          <!-- <div class="box" v-if="listShow">
            <div v-for="item in arr" :key="item.value" @click="chooseItem(item)">
              {{ item.title }}
            </div>
          </div> -->
          <div class="search_str" @click="getCartList">搜索</div>
        </div>
      </div>
      <el-table
        :data="tableData"
        :row-class-name="tableRowClassName"
        ref="multipleTable"
        @select="select"
        @select-all="selectAll"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column prop="date" label="商品信息" align="left" width="350">
          <template slot-scope="{ row }">
            <div class="flex_row_aic cart-img-box" style="position: relative">
              <div class="kegou" v-if="row.show_red">
                <img src="@/assets/img/goods/bg.png" alt="" />
                <p>{{ row.show_red }}</p>
              </div>
              <img style="width: 87px; height: 87px" :src="row.logo" alt="" />
              <div class="info">
                <p class="shenglue_1 bold">{{ row.title }}</p>
                <p>{{ row.sku }}</p>
                <p>{{ row.scqy }}</p>
                <p>{{ row.validity_date }}</p>
                <tagcom :tags="row.tags" :poi="'bottom'"></tagcom>
              </div>
            </div>
            <div class="gray-color" style="color: #999">
              {{ row.show_memo }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="会员价" align="center">
          <template slot-scope="{ row }">
            <span
              v-if="row.show_cart != 102"
              class="f14 mr_10"
              style="color: #ff4c4c"
              >￥{{ row.price }}</span
            >
            <span v-if="row.show_cart == 102" class="f14" style="color: #999"
              >￥{{ row.price }}</span
            >
            <span class="f12" style="text-decoration: line-through; color: #999"
              >{{ row.line_price }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="数量" align="center" width="150">
          <template slot-scope="{ row }">
            <el-input-number
              size="mini"
              :min="Number(row.min_num)"
              :max="Number(row.max_num)"
              :step="Number(row.step)"
              v-model="row.number"
              @change="handleChange($event, row)"
              @blur="blur(row)"
            ></el-input-number>

            <!-- <div class="number_box">
              <div class="left" @click.stop="handleMinus(row)">
                <i class="el-icon-minus"></i>
              </div>
              <div class="num">
                <el-input v-model="row.number" @blur="blur(row)"></el-input>
              </div>
              <div class="right c3" @click.stop="handlePlus(row)">
                <i class="el-icon-plus"></i>
              </div>
            </div> -->
          </template>
        </el-table-column>
        <el-table-column label="合计" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.can_chose == 101" class="f16" style="color: red">
              ￥{{ (Number(row.number) * Number(row.price)).toFixed(2) }}
            </span>
            <span v-else class="f16"> -- </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <span class="c_p" @click="handleDelete(row.id)">删除</span>
            <span
              v-if="row.is_collected != 101"
              class="c_p ml_20"
              @click="handleCollect(row.id)"
              >加入收藏</span
            >
            <span v-else class="c_p ml_20" @click="delCollect(row.id)"
              >移除收藏</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-divider v-if="invalidTable.length > 0" content-position="left"
        >失效品种</el-divider
      >
      <el-table
        v-if="invalidTable.length > 0"
        class="hide-table-header"
        :show-header="false"
        :data="invalidTable"
      >
        <el-table-column
          type="selection"
          :selectable="checkSelectable"
          width="55"
          align="center"
        />
        <el-table-column prop="date" label="商品信息" align="left" width="350">
          <template slot-scope="{ row }">
            <div class="flex_row_aic">
              <img style="width: 87px; height: 87px" :src="row.logo" alt="" />
              <div class="info">
                <p class="shenglue_1 bold">{{ row.title }}</p>
                <p>{{ row.sku }}</p>
                <p>{{ row.validity_date }}</p>
                <tagcom :tags="row.tags" :poi="'bottom'"></tagcom>
              </div>
            </div>
            <div class="gray-color" style="color: #999">
              {{ row.show_memo }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="会员价" align="center">
          <template slot-scope="{ row }">
            <span
              v-if="row.show_cart != 102"
              class="f14 mr_10"
              style="color: #ff4c4c"
              >￥{{ row.price }}</span
            >
            <span v-if="row.show_cart == 102" class="f14" style="color: #999"
              >￥{{ row.price }}</span
            >
            <span class="f12" style="text-decoration: line-through; color: #999"
              >{{ row.line_price }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="数量" align="center" width="150">
          <template slot-scope="{ row }">
            <el-input-number
              :disabled="true"
              size="mini"
              :min="Number(row.min_number)"
              :max="Number(row.max_num)"
              :step="Number(row.step)"
              v-model="row.number"
              @blur="blur(row)"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="合计" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.can_chose == 101" class="f16">
              ￥{{ (Number(row.number) * Number(row.price)).toFixed(2) }}
            </span>
            <span v-else class="f16"> -- </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <span class="c_p" @click="handleDelete(row.id)">删除</span>
            <span class="c_p ml_20" @click="handleCollect(row.id)"
              >加入收藏</span
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="count-box">
        <div class="tips_box c3" ref="fixbar" id="fixbar">
          <!-- 单笔订单必须大于
          <span class="red">{{ info.pzsl }}</span> 个品种才能进行结算，单笔订单满
          <span class="red">{{ info.qfje }}</span>
          才能进行结算，运费 <span class="red">{{ info.yfje }}</span> ，订单满 <span class="red">{{ info.byje }}</span> 包邮， 药品为特殊商品，若无质量问题不予退货，如需退货需自行承担运费
          <span class="red">20</span>
          元起，产生具体退货运费按照实际为准。您可以收到货物
          <span class="red">30</span>
          天内付款，未完成上一笔付款将无法下单! -->
          {{ info.str }}
        </div>

        <!-- <div class="total">
          已选商品共
          <span class="red1"> {{ selectedList.length }}</span
          >件，商品总额（￥{{ total }}），合计
          <span class="red1">￥{{ total }}</span>
        </div> -->
        <div class="yhq_tips" v-if="discountPrice.nexthbje">
          <p>
            再购
            <span class="pricol"> ￥{{ discountPrice.cz }} </span>
            用券商品可再优惠
            <span class="pricol"> ￥{{ discountPrice.nexthbje }}</span>
          </p>
          <p @click="jumpActivity()" class="cur">
            去凑单 <i class="el-icon-arrow-right"></i>
          </p>
        </div>

        <div class="settle flex_sp_c mb_20">
          <div class="flex_row c0">
            <p class="item" @click="$router.push('/index/allGoods')">
              继续购物
            </p>
            <p class="item" @click="clear">清空购物车</p>
          </div>
          <div class="flex_row_aic c0">
            <div class="money_box" v-if="selectedList.length>0">
              <p class="c6 money_one">
                优惠后合计:
                <span class="cf14 f14 bold">￥</span>
                <span class="cf14 f20 bold">{{ (Number(total) - Number(discountPrice.yhj)).toFixed(2)}}</span>
              </p>
              <div class="flex_sp money_two">
                <p class="mr_30 f14">商品总金额：￥{{total}}</p>
                <p class="mr_30 f14">可计满减金额：￥{{mjPrice}}</p>
                <p class="f14">已优惠：￥{{discountPrice.yhj}}</p>
              </div>
            </div>
            <div class="money_box" v-else>
              <p class="c6 money_one">
                合计:
                <span class="cf14 f14 bold">￥</span>
                <span class="cf14 f20 bold">{{ total }}</span>
              </p>
            </div>
            <div class="btn flex_c_c cf" @click="submit">
              <span>下单结算</span>
            </div>
          </div>
        </div>
      </div>
      <recommend />
    </div>
  </div>
</template>

<script>
import recommend from "./recommend.vue";
import tagcom from "@/components/tag.vue";
import { mapGetters } from "vuex";
import { handleData } from "@/utils";
export default {
  components: {
    recommend,
    tagcom,
  },
  data() {
    return {
      kw: "",
      tableData: [],
      invalidTable: [],
      selectedList: [],
      info: {},
      showBar: false,
      mjPrice:0,//参与满减金额
      discountPrice:{},//满减优惠金额
    };
  },
  created() {
    this.getCartList();
    this.$api("cart.getAttr").then((res) => {
      this.info = res.data;
    });
  },
  computed: {
    ...mapGetters(["num"]),
    total() {
      let num = 0;
      this.selectedList.forEach((e) => {
        num += Number(e.number) * Number(e.price);
      });
      return num.toFixed(2);
    },
  },

  methods: {
    // 搜索购物车已有的数据
    // handleEnter() {},
    // handleClick() {},
    getCartList() {
      this.$api("cart.getCartList", { kw: this.kw }).then((res) => {
        this.tableData = res.data.list;
        this.invalidTable = res.data.invalid_list;
        this.selectedList = [];
        this.tableData.forEach((e) => {
          if (e.sel == 101) {
            this.selectedList.push(e);
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(e, true);
            });
          }
        });
        this.computeMjPrice();// 获取到购物车数据后就计算满减金额
        // this.$store.commit("SAVE_BADGE", Number(this.tableData.length) + Number(this.invalidTable.length));
      });
    },
    tableRowClassName({ rowIndex }) {
      // let index = this.tableData.findIndex((item) => item.show_cart == 102);
      let index = this.tableData.findIndex((item) => item.price == "已下架");
      // console.log("下标------------------------", index);
      if (rowIndex == index) {
        return "warning-row";
      }
      return "";
    },
    tableRowStyle({ columnIndex }) {
      if (columnIndex == 2 || columnIndex == 4) {
        return "color:#ff4c4c;";
      }
    },
    async handleDelete(id) {
      // let p = [{ id }];
      await handleData("cart.delCart", { id: id }, "删除数据");
      let index = this.tableData.findIndex((item) => item.id == id);
      this.tableData.splice(index, 1);
      this.getCartList();
      this.$store.dispatch("getBadge");
    },
    handleCollect(id) {
      this.$api("goods.addCollect", { id: id }).then((res) => {
        if (res.code == 200) {
          this.$message.success("收藏成功");
          this.getCartList();
        }
      });
    },
    handleOverlimit() {
      this.$message.error("输入值超出最大限制！");
    },
    delCollect(id) {
      this.$api("account.delCollect", { id: id }).then((res) => {
        if (res.code == 200) {
          this.$message.success("取消收藏成功");
          this.getCartList();
        }
      });
    },
    async clear() {
      let arr = [];
      this.tableData.forEach((e) => {
        arr.push(e.id);
      });
      await handleData("cart.delCart", { ids: arr }, "清空购物车");
      this.getCartList();
      this.$store.dispatch("getBadge");
      this.selectedList = [];
    },
    handleChange(currentValue, item) {
      // let number = item.number;
      // if (item.step != 1) {
      // let num = Number(item.number) / Number(item.step);
      // if (num % 1 !== 0) {
      //   number = Number(item.step) * (parseInt(num) + 1);
      // }
      // }
      // let number = item.number;
      // if (item.step != 1) {
      //   let num = Number(item.number) / Number(item.step);

      //   if (num % 1 !== 0) {
      //     number = Number(item.step) * (parseInt(num) + 1);
      //   }
      // }
      this.$api("cart.updateNum", { id: item.id, number: currentValue }).then(
        () => {
          // this.getCartList();
          this.computeMjPrice(); //重新计算参与满减价格
        }
      );
    },
    blur(item) {
      if (item.step != 1) {
        let num = Number(item.number) / Number(item.step);
        if (num % 1 !== 0) {
          item.number = Number(item.step) * (parseInt(num) + 1);
        }
        this.tableData.forEach((e) => {
          if (e.id == item.id) e = item;
        });
      }
    },
    // ==============================================第二版
    // handleMinus(row) {
    //   let { show_cart, step, number, min_num } =row;
    //   if (show_cart == 102) return;
    //   if (Number(number) <= Number(min_num)) {
    //     this.$message.error("不能再减少了");
    //     return;
    //   }
    //   this.item.number = Number(number) - Number(step);
    // },
    // handlePlus() {
    //   let { show_cart, step, max_num, number } = this.item;
    //   if (show_cart == 102) return;
    //   if (Number(number) + Number(step) > Number(max_num)) {
    //     this.item.number = Number(number) + Number(step) - Number(step);
    //     this.$message.error("已达购买上限");
    //     return;
    //   } else if (Number(this.item.number) == Number(max_num)) {
    //     this.item.number = Number(max_num);
    //   } else {
    //     this.item.number = Number(number) + Number(step);
    //   }
    // },
    // blur(item) {
    //   console.log(item);
    //   if (Number(this.item.number) >= Number(this.item.max_num)) {
    //     this.$message.error(`该商品最大购买量为${this.item.max_num}`);
    //     this.item.number = Math.floor(Number(this.item.max_num) / Number(this.item.step)) * Number(this.item.step);
    //   }
    //   if (Number(this.item.number) < Number(this.item.min_num)) {
    //     this.$message.error(`该商品最小购买量为${this.item.min_num}`);
    //     this.item.number = Number(this.item.min_num);
    //   }
    //   if (Number(this.item.number) % 1 !== 0) {
    //     let num = Math.ceil(Number(this.item.number));
    //     if (num > Number(this.item.max_num)) {
    //       this.$message.error(`该商品最大购买量为${this.item.max_num}`);
    //       this.item.number = Number(this.item.max_num);
    //     } else {
    //       this.item.number = num;
    //     }
    //   }
    // },
    // ================================================================
    checkSelectable(row) {
      if (row.can_chose == 102) {
        return false;
      }
    },
    select(selection, row) {
      this.selectedList = selection;
      let p = [{ id: row.id, sel: row.sel === '101' ? 102 : 101 }];
      this.computeMjPrice();
      this.$api("cart.cartChoose", { goods: p }).then(() => {});
    },
    // 计算参与满减金额
    computeMjPrice(){
      let total = 0;
      this.selectedList.forEach((v) => {
        if (v.hasCoupon) {
          total += Number(v.price) * Number(v.number);
        }
      })
      this.mjPrice = total.toFixed(2);
      this.getDiscountPrice(this.mjPrice)
      return this.mjPrice;
    },
    // 获取优惠金额
    getDiscountPrice(price) {
      this.$api("cart.getDiscount", { kjje:price }).then((res) => {
        this.discountPrice = res.data;
      })
    },
    selectAll(selection) {
      this.selectedList = selection;
      let p = [];
      this.tableData.forEach((v) => {
        if (selection.length == this.tableData.length) {
          p.push({ id: v.id, sel: 101 });
        } else {
          p.push({ id: v.id, sel: 102 });
        }
      });

      this.$api("cart.cartChoose", { goods: p }).then(() => {
        this.computeMjPrice();
      });
    },
    submit() {
      if (!this.selectedList.length) {
        this.$message.warning("请选择结算商品");
        return;
      }
      if (this.selectedList.length < Number(this.info.pzsl)) {
        this.$message.warning(
          `单笔订单需满${this.info.pzsl}个品种才能进行结算！`
        );
        return;
      }
      if (Number(this.total) < Number(this.info.qfje)) {
        this.$message.warning(`单笔订单需满${this.info.qfje}才能进行结算！`);
        return;
      }
      this.$api("cart.submitCart", { goods: this.selectedList }).then((res) => {
        this.$router.push({
          name: "CartCheck",
          params: { ddbh: res.data.ddbh },
        });
      });
    },
    jumpActivity() {
      this.$router.push("/index/activityCenter");
    },
  },
};
</script>

<style lang="less" scoped>
.cart_container {
  background-color: #f9f9f9;
  .top {
    width: 100%;
    height: 74px;
    .c3 {
      font-weight: bold;
    }
  }
  .info {
    text-align: left;
    margin-left: 10px;
    .tag {
      padding: 1px 8px;
      height: 17px;
      line-height: 17px;
      text-align: center;
      border-radius: 2px;
      margin-right: 10px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .step {
    width: 360px;
  }
  .cart_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    &:last-child {
      margin: 0;
    }
    .green {
      margin-top: 10px;
      width: 18px;
      height: 18px;
      background: @themeColor;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      position: relative;
    }
    .grey {
      margin-top: 10px;
      width: 18px;
      height: 18px;
      background: #c6c6c6;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      position: relative;
    }
    .line {
      width: 51px;
      height: 3px;
      background-color: #e8e8e8;
      position: absolute;
      left: 18px;
    }
    .line1 {
      width: 51px;
      height: 3px;
      background-color: #e8e8e8;
      position: absolute;
      right: 18px;
    }
    .active_bg {
      background-color: @themeColor;
    }
  }
  .tips_box {
    width: 709px;
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;
    line-height: 1.6;
    // border-bottom: 1px solid #eeeeee;
  }
  .red {
    color: #f93232;
    font-weight: bold;
  }
  .red1 {
    color: #f93232;
  }
  .total {
    width: 100%;
    height: 50px;
    background: #fff7f0;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 120px;
    box-sizing: border-box;
    color: #000;
  }
  .yhq_tips {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    align-items: center;
    border: 1px solid #ff8933;
    background: #fff3eb;
    height: 36px;
    font-size: 14px;
    .pricol {
      color: #f96464;
    }
    .cur {
      cursor: pointer;
    }
  }
  .settle {
    width: 100%;
    height: 80px;
    background: #fff;
    box-shadow: 0 2px 1px 0px #eee;
    .item {
      margin-left: 40px;
      cursor: pointer;
    }
    .btn {
      width: 150px;
      height: 80px;
      background: #f04844;
      cursor: pointer;
      font-size: 18px;
      .el-icon-arrow-right {
        font-size: 15px;
      }
    }
  }
  .count-box {
    background-color: #fff;
    position: sticky;
    z-index: 99;
    bottom: 0; /* 固定在底部 */
  }
}
// 浮动结算栏
.float-bar {
  // width: 1200px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 99;
  .total {
    width: 1200px;
    margin: 0 auto;
  }

  .settle {
    width: 1200px;
    margin: 0 auto;
  }
}
// 筛选栏
.filter-cart {
  width: 100%;
  height: 60px;
  // background-color: #5db52d;
  display: flex;
  justify-content: flex-end;
  .serach_box {
    height: 34px;
    display: flex;
    .input-w {
      width: 210px;
      height: 34px;
      border: 1px solid @themeColor;
      ::v-deep .el-input__inner {
        width: 210px;
        height: 34px;
        border: none;
      }
    }
    .box {
      z-index: 1000;
      position: absolute;
      top: 60px;
      background: #fff;
      width: 190px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      padding: 0 10px;
      max-height: 450px;
      overflow-y: auto;
      line-height: 1.6;
      div {
        cursor: pointer;
        &:hover {
          color: @themeColor;
        }
      }
    }
    .search_str {
      cursor: pointer;
      width: 40px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: @themeColor;
      font-size: 12px;
      color: #ffffff;
      border: 1px solid @themeColor;
    }
  }
}
/deep/.el-table .warning-row {
  // background: rgb(103, 156, 247);
  background-color: rgb(247, 247, 248) !important;
}

/deep/.el-table .success-row {
  // background: #5db52d;
  background-color: #5db52d !important;
}
.hide-table-header /deep/ .el-table__header-wrapper {
  display: none !important;
}
.number_box {
  width: 120px;
  height: 26px;
  border: 1px solid #dddddd;
  border-radius: 2px;
  display: flex;
  .left {
    color: #ddd;
  }
  .left,
  .right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .num {
    width: 36px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dddddd;
    // border-left: 1px solid #dddddd;
    // border-right: 1px solid #dddddd;
    ::v-deep .el-input__inner {
      padding: 0 4px;
      height: 26px;
      border: none;
      text-align: center;
    }
  }
  .gray-color {
    color: #999 !important;
  }
}
.cart-img-box {
  position: relative;
  .kegou {
    top: 0;
    left: 0;
    position: absolute;
    width: 86px;
    height: 22px;
    p {
      font-size: 12px;
      position: absolute;
      top: 2px;
      left: 6px;
    }
  }
}
.money_box {
  margin-right: 20px;
  font-size: 16px;
  .money_one{
    text-align: right;
  }
  .money_two{
    color: #797979;
    margin-top: 6px;
  }
}
</style>
