<template>
  <div class="subrouter_container">
    <div class="content">
      <div class="left">
        <div v-for="(item, index) in newsList" :key="index">
          <div class="title" @click="getList(item)">{{ item.title }}</div>
          <div class="item" :class="current == sub.id ? 'active' : ''" v-for="sub in item.list" :key="sub.id" @click="newsClick(sub, index)">
            {{ sub.title }}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="mb_20 breadcrumb" style="display: flex">
          <div>{{ ffdata.title }}</div>
          <span> /</span>
          <div class="" @click="back">{{ ssdata.title }}</div>
          <span v-if="dd"> /</span>
          <div>{{ dd }}</div>
        </div>
        <div v-if="detailFlag == 'list'">
          <div v-for="item in rightList" class="list-item" :key="item.id" @click="getListDetail(item)">{{ item.title }}</div>
        </div>
        <div v-if="detailFlag == 'detail'">
          <div v-html="newsDetail.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0,
      currentData: {},
      newsList: [],
      newsDetail: {},
      fatherCateInd: 0,
      detailFlag: "detail",
      rightList: [],
      ffdata: {},
      ssdata: {},
      ftitle: "",
      dd: "",
      show: true,
      info: "",
    };
  },
  created() {
    // console.log("新闻id ------", this.$route.query.id);
    // console.log("新闻类型 ------", this.$route.query.type);
    this.$api("news.getNewsCate").then((res) => {
      this.newsList = res.data;
      if (this.$route.query.id) {
        if (this.$route.query.type == "news") {
          this.getItem(this.$route.query.id, this.$route.query.name, "news");
        }
        if (this.$route.query.type == "help") {
          let fid = this.$route.query.fid;
          this.newsList.forEach((e) => {
            e.list.forEach((v) => {
              // console.log(v);
              if (fid == v.id) {
                // console.log("匹配项的父级---", e);
                // console.log("匹配项--666---", v);
                this.ffdata = e;
                this.ssdata = v;
                this.dd = this.$route.query.name;
                this.detailFlag == "detail";
                console.log(this.$route.query.type, this.$route.query.id);
                this.$api("news.getNewsCateInfo", { type: this.$route.query.type, id: this.$route.query.id }).then((res) => {
                  this.current = this.ssdata.id;
                  this.newsDetail = res.data;
                });
              }
            });
          });
        }
      } else {
        this.newsClick(this.newsList[0].list[0]);
      }
    });
  },
  watch: {
    $route(to) {
      console.log(to);
      this.$api("news.getNewsCate").then((res) => {
        this.newsList = res.data;
        if (this.$route.query.id) {
          if (this.$route.query.type == "news") {
            this.getItem(this.$route.query.id, this.$route.query.name, "news");
          }
          if (this.$route.query.type == "help") {
            let fid = this.$route.query.fid;
            this.newsList.forEach((e) => {
              e.list.forEach((v) => {
                // console.log(v);
                if (fid == v.id) {
                  // console.log("匹配项的父级---", e);
                  // console.log("匹配项--666---", v);
                  this.ffdata = e;
                  this.ssdata = v;
                  this.dd = this.$route.query.name;
                  this.detailFlag == "detail";
                  console.log(this.$route.query.type, this.$route.query.id);
                  this.$api("news.getNewsCateInfo", { type: this.$route.query.type, id: this.$route.query.id }).then((res) => {
                    this.current = this.ssdata.id;
                    this.newsDetail = res.data;
                  });
                }
              });
            });
          }
        } else {
          this.newsClick(this.newsList[0].list[0]);
        }
      });
    },
  },
  mounted() {
    this.$bus.$on("updateNews", this.updateNews);
  },
  methods: {
    getNewsDetail(type, id) {
      this.$api("news.getNewsCateInfo", { type: type, id: id }).then((res) => {
        // this.current = id;
        this.current = this.ssdata.id;
        this.newsDetail = res.data;
      });
    },
    updateNews(data) {
      this.getItem(data.id);
    },
    getItem(ID, name, type) {
      console.log("111---name---", name);
      this.newsList.forEach((e, index) => {
        // console.log("列表e----", e);
        e.list.forEach((v, ind) => {
          // if (!name) {
          if (v.id == ID) {
            console.log("匹配到的项--v--", v, ind);
            console.log("匹配到的父级项----", e.title, index);
            this.ffdata = e;
            this.ssdata = v;
            if (e.show == "detail") {
              this.newsClick(v, index, ind);
              this.getNewsDetail(type, v.id);
            }
            if (e.show == "list") {
              this.newsClick(v, index, ind);
              // this.ffdata = e;
              // this.ssdata = v;
              this.getTypeList(v.id);

              this.detailFlag = "list";
            }
          }
          // } else {
          //   console.log("222---name----", v);
          //   v.list.forEach((o, ind) => {
          //     if (o.id == ID) {
          //       this.newsClick(v, ind);
          //       this.getData(o);
          //       this.dd = o.title;
          //     }
          //   });
          // }
        });
      });
    },
    getTypeList(id) {
      this.$api("news.getcateslist", { id: id }).then((res) => {
        console.log("新闻分类列表---", res);
        if (res.code == 200) {
          this.rightList = res.data;
        }
      });
    },
    // getList(data) {
    //   this.getTypeList(data.id);
    // },
    newsClick(item, index, ind) {
      console.log("切换----", item, index);
      this.current = item.id;
      this.fatherCateInd = ind;
      this.detailFlag = this.newsList[index].show;
      this.ffdata = this.newsList[index];
      // this.ffdata = item;
      // this.ftitle = item.title;
      this.ssdata = item;
      this.dd = "";
      if (this.detailFlag == "list") {
        this.getTypeList(item.id);
      }
      if (this.detailFlag == "detail") {
        this.getNewsDetail(item.type, item.id);
      }
    },
    getListDetail(item) {
      this.detailFlag = "detail";
      this.dd = item.title;
      this.getNewsDetail(item.type, item.id);
    },
    getData(data) {
      this.show = false;
      this.dd = data.title;
      this.$api("news.getNewsDel", data).then((res) => {
        this.info = res.data.content;
      });
    },
    back() {
      // this.show = true;
      // this.dd = "";
      console.log("ff-----", this.ffdata);
      console.log("ss-----", this.ssdata);
      this.detailFlag = this.ffdata.show;
      if (this.ffdata.show == "list") {
        this.getTypeList(this.ssdata.id);
        this.dd = "";
      }
    },
  },
  destroyed() {
    this.$bus.$off("updateNews", this.updateNews);
  },
};
</script>

<style lang="less" scoped>
.subrouter_container {
  padding-top: 10px;
  .content {
    display: flex;
    .left {
      border-radius: 4px;
      width: 220px;
      background-color: #fff;
      margin-right: 10px;
      .title {
        height: 36px;
        line-height: 36px;
        font-size: 20px;
        font-weight: bold;
        padding-left: 20px;
        margin: 15px 0;
        border-left: 4px solid @themeColor;
      }
      .item {
        color: #666666;
        height: 40px;
        line-height: 40px;
        padding-left: 12px;
        cursor: pointer;
        &:hover {
          color: @themeColor;
        }
        &.active {
          color: @themeColor;
        }
      }
    }
    .right {
      border-radius: 4px;
      flex: 1;
      background-color: #fff;
      padding: 20px;
      overflow: hidden;
      .list-item {
        cursor: pointer;
        padding: 4px 0;
        border-bottom: 1px dotted #999;
      }
      img{
        max-width: 100%;
      }
    }
  }
  .newsitem {
    font-size: 15px;
    color: #333;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      color: @themeColor;
    }
  }
  .breadcrumb {
    display: flex;
    align-items: center;
    color: #333;
    div {
      cursor: pointer;
    }
    span {
      margin: 0 10px;
    }
  }
}
</style>
