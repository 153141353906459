<template>
  <section class="page-content" id="index">
    <!-- 广告 -->
    <!-- <div class="ad-content" v-if="adFlag && isHome && showADV">
      <div class="ad-img">
        <div class="close-ad" @click="closeAD">
          <i class="el-icon-close" size="20"></i>
        </div>
        <img :src="homeADimg" @click.stop="adJump" alt="" />
      </div>
    </div> -->
    <!-- 顶部搜索 -->
    <headertop :headerMenu="topData" :show="show" />
    <!-- 右边栏 -->
    <sidebar />
    <!-- 顶部广告、tab -->
    <top :headerMenu="topData" />
    <!-- logo search-->
    <navbar :headerMenu="topData" />
    <!-- 分类 -->
    <cate :headerMenu="topData" @scollTop="scollTop" />

    <router-view />
    <!-- foot -->
    <foot :headerMenu="bottomData" :helpData="bottomData.help" />
  </section>
</template>

<script>
import { LsetItem } from "@/utils/storage";
import sidebar from "./components/sidebar";
import top from "./components/top";
import navbar from "./components/nav";
import cate from "./components/cate";
import foot from "./components/foot";
import headertop from "./components/headertop";
export default {
  components: {
    sidebar,
    top,
    navbar,
    cate,
    foot,
    headertop,
  },
  data() {
    return {
      adFlag: true,
      showADV: false,
      isHome: false,
      show: false,
      topData: {},
      bottomData: {},
      // pageData: this.$store.state.homeData,
    };
  },

  created() {
    // this.$api("home.getNavInfo").then((res) => {
    //   this.topData = res.data;
    //   this.$store.commit("SAVE_HOMECOMDATA", res.data);
    //   this.$store.commit("CHANGE_MENUDATA", this.topData.areas);
    //   this.$store.commit("SAVE_SETTING", this.topData.setting);
    //   LsetItem("setting", this.topData.setting);
    // });
    // this.$api("home.getHomeAdv").then((res) => {
    //   // console.log("广告内容----", res);
    //   if (res.code == 200) {
    //     if (res.data.is_show == 101) {
    //       this.showADV = true;
    //     }
    //     this.homeADimg = res.data.logo;
    //     this.adData = res.data;
    //   }
    // });
    this.$api("home.getComHeader").then((res) => {
      this.topData = res.data;
      this.$store.commit("SAVE_HOMECOMDATA", res.data);
      this.$store.commit("CHANGE_MENUDATA", this.topData.areas);
      LsetItem("setting", this.topData.setting);
      localStorage.setItem("small_logo", res.data.setting.logo);
    });
    this.$api("home.getComBottom").then((res) => {
      this.bottomData = res.data;
      localStorage.setItem("bottomData", JSON.stringify(res.data));
    });
  },
  methods: {
    scollTop(val) {
      this.show = val;
    },
    closeAD() {
      this.adFlag = false;
    },
    // adJump() {
    //   console.log("广告跳转---", this.adData.pcJump);
    // },
    // getData() {
    //   this.$api("home.hoemData").then((res) => {
    //     this.pageData = res.data;
    //     this.$store.commit("SAVE_HOMEDATA", res.data);
    //     console.log("首页数据---", this.pageData);
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
section {
  width: 100%;
  height: 100%;
}
</style>

<style lang="less">
.custombtn {
  background-color: @themeColor;
  border-color: @themeColor;
}
.custombtn:hover,
.custombtn:focus {
  background-color: @themeColor;
  border-color: @themeColor;
}
// input
.el-input__inner:focus {
  border-color: @themeColor;
}
//textarea
.el-textarea__inner:focus {
  border-color: @themeColor;
}

// select
.el-select .el-input.is-focus .el-input__inner {
  border-color: @themeColor;
}
.el-input.is-focus {
  border-color: @themeColor;
}
.el-select .el-input__inner:focus {
  border-color: @themeColor;
}
.el-select-dropdown__item.selected {
  color: @themeColor;
}

// checkbox
/* 设置选中后的文字颜色 */
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: @themeColor;
}

/* 设置选中后对勾框的边框和背景颜色 */
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: @themeColor;
  background-color: @themeColor;
}
/* 设置带边框的checkbox，选中后边框的颜色 */
.is-bordered.is-checked {
  border-color: @themeColor;
}

/* 设置checkbox获得焦点后，对勾框的边框颜色 */
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: @themeColor;
}

/* 设置鼠标经过对勾框，对勾框边框的颜色 */
.el-checkbox__inner:hover {
  border-color: @themeColor;
}

// ascader
.el-cascader .el-input .el-input__inner:focus,
.el-cascader .el-input.is-focus .el-input__inner {
  border-color: @themeColor;
}

.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
  color: @themeColor;
}

//date
.el-date-table td.current:not(.disabled) span {
  background-color: @themeColor;
}

.el-date-table td.today span {
  color: @themeColor;
}
.el-date-table td.today:hover {
  color: @themeColor;
}

.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: @themeColor;
}
.el-button--text {
  color: @themeColor;
}
.el-button--text:hover {
  color: @themeColor;
}
.el-button.is-plain:focus,
.el-button.is-plain:hover {
  color: @themeColor;
  border-color: @themeColor;
}
.el-range-editor.is-active,
.el-range-editor.is-active:hover,
.el-select .el-input.is-focus .el-input__inner {
  border-color: @themeColor;
}
.el-date-table td.available:hover {
  color: @themeColor;
}
.el-time-panel__btn.confirm {
  color: @themeColor;
}

// tab
.el-tabs__item.is-active {
  color: @themeColor;
}
.el-tabs__item:hover {
  color: @themeColor;
}
.el-tabs__active-bar {
  background-color: @themeColor;
}

//.el-radio
.el-radio__input.is-checked + .el-radio__label {
  color: @themeColor;
}
.el-radio__input.is-checked .el-radio__inner {
  background: @themeColor;
  border-color: @themeColor;
}
.el-radio__input .el-radio__inner:hover {
  border-color: @themeColor;
}

//.el-input-number
.el-input-number--mini:hover {
  border-color: @themeColor;
}
.el-input-number__decrease:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled),
.el-input-number__increase:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled) {
  border-color: @themeColor;
}

.el-input-number__decrease:hover,
.el-input-number__increase:hover {
  color: @themeColor;
}

//table  sort
.el-table .descending .sort-caret.descending {
  border-top-color: @themeColor;
}
.el-table .ascending .sort-caret.ascending {
  border-bottom-color: @themeColor;
}
.page-content {
  position: relative;
  .ad-content {
    background-color: rgba(31, 29, 29, 0.8);
    z-index: 9998;
    width: 100vw;
    height: 100vw;
    position: fixed;
    .ad-img {
      width: 800px;
    height: 600px;
    margin: 0 auto;
    vertical-align: middle;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
      .close-ad {
        position: absolute;
        cursor: pointer;
        font-size: 24px;
        top: -10px;
        color: #fff;
        z-index: 99;
        right: 0;
      }
    }
  }
}
</style>
