<template>
  <div>
    <!-- <div></div> -->
    <div v-if="type == 101">
      <div class="tab_wrap">
        <div @click="choose(0)" :class="current == 0 ? 'active' : ''">所有订单</div>
        <!-- <div @click="choose(108)" :class="current == 108 ? 'active' : ''">
        待确认 <span>{{ info.order108_num }}</span>
      </div> -->
        <div @click="choose(1)" :class="['order-tab', current == 1 ? 'active' : '']">
          待付款
          <div v-if="Number(orderNum.order1_num) > 0" class="num-box">{{ orderNum.order1_num }}</div>
        </div>
        <div @click="choose(2)" :class="current == 2 ? 'active' : ''">
          待发货
          <div v-if="Number(orderNum.order2_num) > 0" class="num-box">{{ orderNum.order2_num }}</div>
        </div>
        <div @click="choose(3)" :class="current == 3 ? 'active' : ''">
          待收货
          <div v-if="Number(orderNum.order3_num) > 0" class="num-box">{{ orderNum.order3_num }}</div>
        </div>
        <div @click="choose(4)" :class="current == 4 ? 'active' : ''">
          已完成
          <div v-if="Number(orderNum.order4_num) > 0" class="num-box">{{ orderNum.order4_num }}</div>
        </div>
        <div @click="choose(5)" :class="current == 5 ? 'active' : ''">
          已取消
          <div v-if="Number(orderNum.order5_num) > 0" class="num-box">{{ orderNum.order5_num }}</div>
        </div>
        <div @click="choose(6)" :class="current == 6 ? 'active' : ''">
          待退款
          <div v-if="Number(orderNum.order6_num) > 0" class="num-box">{{ orderNum.order6_num }}</div>
        </div>
      </div>
      <el-form :inline="true" :model="form" class="form">
        <el-form-item label="关键字：">
          <el-input v-model="form.kw" placeholder="商品名称/订单编号" clearable></el-input>
        </el-form-item>
        <el-form-item label="时间:">
          <el-date-picker v-model="value1" type="daterange" @change="changeTime" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="订单编号：">
          <el-input v-model="form.order_no" placeholder="订单编号" clearable></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="订单状态：">
          <el-select v-model="form.status" placeholder="请选择" clearable>
            <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id"> </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="getOrder">筛选订单</el-button>
        </el-form-item>
      </el-form>
      <div class="order_main" v-if="list.length > 0">
        <div class="item" v-for="item in list" :key="item.id">
          <div class="item_top flex_sp_c">
            <div class="flex_row_aic">
              <p class="til">{{ item.status_str }}</p>
              <p class="c6">
                订单号：<span class="c3">{{ item.orderno }}</span>
              </p>
              <p class="c6">
                订单时间：<span class="c3">{{ item.order_time }}</span>
              </p>
              <!-- <span class="c3" style="margin-right: 10px">胡汉三</span>
            <span class="red">暂无</span> -->
            </div>
            <span class="pr">￥{{ formatAmount(item.total_price) }}</span>
          </div>
          <div class="item_cen flex_sp">
            <div class="flex_row left_box">
              <div class="img_box" v-for="sub in item.goods" :key="sub.id">
                <div>
                  <img style="width: 110px; height: 110px" :src="sub.image" alt="" />
                  <p class="c3 shenglue_1">{{ sub.title }}</p>
                  <p class="p1">
                    ￥{{ sub.price }}
                    <span class="c6" style="font-size: 14px"> x{{ sub.number }} </span>
                  </p>
                </div>
              </div>
            </div>
            <div style="width: 90px">
              <!-- <div v-for="sub in item.buttons" :key="sub.type" @click="btnClick(sub.type, item)" class="btn flex_c_c c3">{{ sub.value }}</div> -->
              <div class="btn flex_c_c c3" @click="handleDetail(item)">查看明细</div>
              <div v-if="item.status == 3 || item.status == 4" class="btn flex_c_c c3" @click="downOrder(item)">下载订单</div>
              <div v-if="item.btn_aftersale == 101" class="btn flex_c_c" @click="applayAfterSale(item)">申请售后</div>
              <div v-if="item.btn_cancle == 101" class="btn flex_c_c" @click="cancleOrder(item)">取消订单</div>
              <div v-if="item.btn_logistics == 101" class="btn flex_c_c" @click="readLogistic(item)">查看物流</div>
              <div v-if="item.btn_pay == 101" class="btn flex_c_c" @click="payNow(item)">立即支付</div>
              <div v-if="item.btn_receipt == 101" class="btn flex_c_c" @click="confirmReceipt(item)">确认收货</div>
              <div v-if="item.btn_rebuy == 101" class="btn flex_c_c" @click="buyTwices(item)">再次购买</div>
            </div>
          </div>
          <div v-if="item.p_order" class="main-order">{{ item.p_order }}</div>
        </div>
      </div>
      <div class="empty" v-else>
        <el-empty :image-size="200"></el-empty>
      </div>
      <pagination :total="total" :currentPage="page" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
    </div>
    <div v-if="type == 102">
      <div class="compont_p flex-r">
        立即支付
        <div class="btn flex_c_c btn1" @click="backPre(101)">返回上级</div>
      </div>
      <div class="order-pay-info">
        <div class="pay-info-item">订单编号：{{ payInfo.ddbh }}</div>
        <div class="pay-info-item">
          订单金额：<span style="color: #ff4c4c">￥</span><span class="pay-money">{{ payInfo.pay_amount }}</span>
        </div>
      </div>
      <div class="pay-style">支付方式</div>
      <div class="pay-box">
        <div v-for="(item, index) in payList" class="pay-item" :key="index" @click="choosePay(item, index)">
          <img :src="item.logo" :class="['pay-img', payIndex == index ? 'active-pay' : '']" alt="" />
        </div>
      </div>
      <el-button type="primary" @click="nextStep">下一步</el-button>
    </div>
    <!-- 支付弹窗 -->
    <el-dialog :title="payTitle" class="pay-pop" :visible.sync="payFlag" @close="closePop" width="40%">
      <!-- <div class="pay-money">{{ paySubTitle }}</div> -->
      <img style="width: 200px; height: 200px" :src="payQRcode" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";
import request from "@/api/request";
import { handleData, formatAmount, saveFile } from "@/utils";
import { Message } from "element-ui";
export default {
  components: {
    pagination,
  },
  data() {
    return {
      current: 0,
      orderNum: {}, //订单数量
      info: {},
      type: 101,
      value1: "",
      form: {
        kw: "",
        order_no: "",
        status: "",
        time_start: "",
        time_end: "",
      },
      options: [
        {
          id: 0,
          title: "全部",
        },
        {
          id: 1,
          title: "待付款",
        },
        {
          id: 2,
          title: "待发货",
        },
        {
          id: 3,
          title: "待收货",
        },
        {
          id: 4,
          title: "已完成",
        },
        {
          id: 5,
          title: "已取消",
        },
        {
          id: 6,
          title: "待退款",
        },
      ],
      list: [],
      total: 0,
      page: 1,
      page_size: 10,
      payList: [],
      payIndex: 0,
      payInfo: {},
      payStyle: {},
      payTitle: "支付信息",
      paySubTitle: "支付信息",
      payFlag: false,
      payQRcode: "",
    };
  },
  created() {
    // console.log("订单序号----", this.$route.query.current);
    this.getOrderNum();
    let index = this.$route.query.current;

    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    if (this.$route.query.current) {
      this.current = this.$route.query.current;
    }
    if (this.type == 102) {
      this.getPayData(this.$route.query.ddbh);
      // this.$api("cart.getPay", { ddbh: this.$route.query.ddbh }).then((res) => {
      //   this.payList = res.data;
      // });
    }
    this.current = index || 0;
    this.getOrder();

    // this.$api("account.getOrderTime").then((res) => {
    //   this.options = res.data;
    // });
  },
  methods: {
    formatAmount,
    getOrderNum() {
      this.$api("account.orderCounts").then((res) => {
        // console.log("订单数量-----", res);
        this.orderNum = res.data;
      });
    },
    getOrder() {
      // console.log("订单筛选----", this.form);
      if (this.value1 && this.value1.length > 0) {
        let Atime = Date.parse(new Date(this.value1[0])) / 1000;
        let Btime = Date.parse(new Date(this.value1[1])) / 1000;
        if (Atime > Btime) {
          this.form.time_end = Atime;
          this.form.time_start = Btime;
        } else {
          this.form.time_end = Btime;
          this.form.time_start = Atime;
        }
      }
      this.$api("account.getOrder", {
        // status: this.current,
        ...this.form,
        status: this.current,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.list = res.data.list;
        this.total = res.data.count;
        // console.log("订单列表---", this.list);
      });
      this.getTotal();
    },
    backPre(num) {
      this.type = num;
      this.$router.push({
        path: "/index/subRouter/order",
      });
      // this.getOrder();
    },
    getTotal() {
      this.$api("account.getMyInfo").then((res) => {
        this.info = res.data;
      });
    },
    choose(i) {
      this.current = i;
      this.page = 1;
      this.form.kw = "";
      this.getOrderNum();
      this.getOrder();
    },
    changeTime(e) {
      if (!e) {
        this.form.time_end = "";
        this.form.time_start = "";
      }
    },
    // 下载订单
    downOrder(data) {
      this.$api("account.orderDown", { id: data.id }).then((res) => {
        console.log("下载订单----", res);
        saveFile(res.data.title, res.data.key, res.data.list, res.data.file_name);
      });
    },
    // 查看详情
    handleDetail(row) {
      this.$router.push({
        path: "/index/subRouter/orderDetail",
        query: { id: row.id, orderno: row.orderno },
        // query: { id: row.id },
      });
    },
    // 查看物流
    readLogistic(row) {
      this.$router.push({
        path: "/index/subRouter/logistics",
        query: { id: row.id, orderno: row.orderno, type: "DD" },
      });
    },
    // 取消订单
    cancleOrder(item) {
      this.$confirm("确定取消订单吗？", "取消订单", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api("account.cancelOrder", { orderno: item.orderno, id: item.id }).then((res) => {
            if (res.code == 200) {
              this.$message.success("取消成功");
              this.getOrder();
              this.getOrderNum();
            }
          });
        })
        .catch(() => {});
    },
    // 申请售后
    applayAfterSale(item) {
      console.log("订单信息---", item);
      this.$router.push({
        // name: "ApplyAfterSale",
        // params: { orderno: item.orderno, id: item.id },subRouter/orderCenter/applyAfterSale
        path: "/index/subRouter/applyAfterSale",
        query: { orderno: item.orderno, id: item.id },
      });
    },

    getPayData(ddbh) {
      this.$api("cart.getPay", { ddbh: ddbh }).then((res) => {
        this.payInfo = res.data;
        this.payList = res.data.list;
        let index = res.data.list.findIndex((item) => item.is_default == 101);
        console.log("默认选中的下标---", index);
        this.payIndex = index;
        this.payStyle = this.payList[index];
        // let choosed = res.data.filter((v) => {
        //   return v.is_default == 101
        // })
      });
    },
    // 打开支付列表
    payNow(data) {
      // console.log("支付信息-----", data);
      // this.payInfo = data;
      // this.getPayData(data.orderno);
      // this.type = 102;
      // 跳转到新支付页面
      this.$router.push({
        path: "/index/payOrder",
        query: { type: 102, ddbh: data.orderno },
      });
    },
    // 选择支付方式
    choosePay(data, ind) {
      this.payIndex = ind;
      console.log("选择的支付方式-----", data);
      this.payStyle = data;
    },
    // 支付-下一步
    nextStep() {
      console.log("111----", this.payInfo);
      if (this.payStyle.url) {
        let url = this.payStyle.url.substr(3, this.payStyle.url.length - 1);
        const arg = {
          url: url,
          method: "post",
          params: {
            // ddbh: this.payInfo.orderno,
            ddbh: this.payInfo.ddbh,
          },
        };
        console.log("支付信息参数----", arg);
        request(arg).then((r) => {
          // console.log("666----", r);
          // type：105 弹窗支付
          if (this.payStyle.type == 105) {
            this.payTitle = r.data.title;
            this.payQRcode = r.data.logo;
            this.payFlag = true;
          }
          if (this.payStyle.type == 106) {
            if (r.data.success == 1) {
              this.type = 101;
              this.current = 2;
            } else {
              this.type = 101;
              this.current = 1;
            }
          }
        });
      } else {
        Message.error("支付出错啦~");
        this.type = 101;
        this.current = 1;
      }
    },
    // 查询支付结果
    getPayStatus(ddbh) {
      this.$api("cart.payStatus", { ddbh: ddbh }).then((res) => {
        if (res.data == 101) {
          this.$confirm("订单支付失败", "提示", {
            confirmButtonText: "关闭",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              if (ddbh.substring(0, 2) == "CZ") {
                this.$router.push({
                  path: "/index/subRouter/balance",
                });
              } else {
                this.type = 101;
                this.current = 1;
              }
            })
            .catch(() => {
              if (ddbh.substring(0, 2) == "CZ") {
                this.$router.push({
                  path: "/index/subRouter/balance",
                });
              } else {
                this.type = 101;
                this.current = 1;
              }
            });
        }
        if (res.data == 102) {
          this.$confirm("订单支付成功", "提示", {
            confirmButtonText: "关闭",
            cancelButtonText: "取消",
            type: "success",
          })
            .then(() => {
              this.type = 101;
              this.current = 2;
            })
            .catch(() => {
              this.type = 101;
              this.current = 2;
            });
        }
      });
    },
    // 关闭支付弹窗
    closePop() {
      this.payFlag = false;
      this.getPayStatus(this.payInfo.ddbh);
    },
    // 确认收货
    confirmReceipt(row) {
      this.$confirm("确认收货吗？", "确认收货", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api("account.deliveryOrder", { orderno: row.orderno, id: row.id }).then(() => {
            this.$message.success("收货成功");
            this.getOrder();
            this.getOrderNum();
          });
        })
        .catch(() => {});
    },
    buyTwices(row) {
      this.$api("account.buyTwice", { id: row.id, orderno: row.orderno }).then((res) => {
        if (res.code == 200) {
          this.$message.success("已加入购物车");
          this.$store.dispatch("getBadge");
        }
        // this.$message.success("已加入购物车");
        // this.$store.dispatch("getBadge");
      });
    },
    // 101取消订单 102立即支付 103确认收货 104再次购买 105申请售后  106物流跟踪
    async btnClick(type, item) {
      if (type == 101) {
        await handleData("account.cancelOrder", { id: item.id, orderno: item.orderno }, "取消订单");
      }
      if (type == 102) {
        //立即支付
        this.$api("cart.weixinpay", { ddbh: item.orderno }).then((res) => {
          if (res.data.code == "101") {
            //获取到支付二维码
            // this.$alert(res.data.err, '错误', {
            //   confirmButtonText: '确定',
            //   callback: () => {
            //     this.$router.replace({
            //       path: "/index/subRouter/order",
            //       query: { index: 102 },
            //     });
            //   }
            // });
          } else {
            this.$alert(res.data.err, "错误", {
              confirmButtonText: "确定",
              callback: () => {},
            });
          }
        });
      }
      if (type == 103) {
        await handleData("account.deliveryOrder", { id: item.id, orderno: item.orderno }, "确认收货");
      }
      if (type == 104) {
        this.$api("account.buyTwice", { order_id: item.orderno }).then((res) => {
          if (res.code == 200) {
            this.$message.success("已加入购物车");
            this.$store.dispatch("getBadge");
          }
        });
      }
      if (type == 105) {
        this.$router.push({
          name: "ReturnGoods",
          params: { orderno: item.orderno, id: item.id },
        });
      }
      this.getOrder();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getOrder();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getOrder();
    },
  },
};
</script>

<style lang="less" scoped>
.tab_wrap {
  width: 100%;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  border-bottom: 1px solid #eeeeee;

  >div {
    margin-right: 26px;
    padding-bottom: 10px;
    display: flex;
    cursor: pointer;
    position: relative;
    width: 80px;
    justify-content: center;
    &.active {
      color: @themeColor;
      font-weight: bold;
      border-bottom: 2px solid @themeColor;
    }
    .num-box {
      color: #fff;
      position: absolute;
      background-color: #f56c6c;
      height: 18px;
      line-height: 18px;
      width: 18px;
      text-align: center;
      font-size: 14px;
      border-radius: 50%;
      top: -5px;
      right: -2px;
      font-weight: lighter;
    }
  }
  span {
    color: @themeColor;
    margin-left: 5px;
  }
}
.form {
  margin-top: 20px;
  .el-button {
    background-color: @themeColor;
    border-color: @themeColor;
  }
}
.order_main {
  .item {
    width: 950px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-bottom: 20px;
    .item_top {
      height: 50px;
      background: #f3f3f3;
      padding: 0 15px;
      .til {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }
      p {
        margin-right: 20px;
      }
      .red {
        color: @priceRed;
      }
      .pr {
        font-size: 18px;
        font-weight: bold;
        color: @priceRed;
      }
    }
    .main-order {
      height: 30px;
      // background: #f3f3f3;
      padding: 0 15px;
    }
    .item_cen {
      padding: 20px;
      .left_box {
        flex: 1;
        overflow-x: auto;
        margin-right: 40px;
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(40, 44, 52, 0.1);
          border: 3px solid transparent;
          border-radius: 7px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba(40, 44, 52, 0.1);
        }
      }
      .img_box {
        margin-right: 20px;
        .p1 {
          font-size: 16px;
          font-weight: 400;
          color: @priceRed;
        }
      }
    }
    .btn {
      width: 87px;
      height: 33px;
      line-height: 33px;
      border: 1px solid #cdcdcd;
      border-radius: 4px;
      margin-bottom: 20px;
      cursor: pointer;
      &:last-child {
        margin: 0;
      }
    }
  }
}
.compont_p {
  position: relative;
  .btn {
    position: absolute;
    left: 110px;
    top: 0;
    width: 76px;
    height: 30px;
    line-height: 30px;
    background: #ff4c4c;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
  .btn1 {
    background: @themeColor;
  }
}
.order-pay-info {
  margin-top: 20px;
  .pay-info-item {
    margin-bottom: 10px;
    .pay-money {
      font-size: 20px;
      color: #ff4c4c;
    }
  }
}
.pay-style {
  font-size: 20px;
  margin-top: 20px;
}
.pay-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;

  // height: 100px;
  // background-color: burlywood;
  .pay-item {
    width: 22%;
    margin-bottom: 20px;
    margin-right: 28px;
    .pay-img {
      width: 100px;
      height: 80px;
      margin-right: 10px;
      border: 3px solid #999;
    }
    .active-pay {
      border: 3px solid #0abc64;
    }
  }
}
.pay-pop {
  text-align: center;
  padding-bottom: 20px;
  .pay-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: #333;
  }
  .pay-money {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: #53a8ff;
    margin-bottom: 20px;
  }
}
</style>
