<template>
  <div class="pag_wrap">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="pageSizes"
      layout="prev, pager, next, jumper"
      prev-text="上一页"
      :page-size="20"
      next-text="下一页"
      :total="total"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30],
    },
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
      this.scrollTop()
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
      this.scrollTop()
    },
    scrollTop(){
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }
  },
};
</script>

<style lang="less">
.pag_wrap {
  margin-top: 30px;
  padding-bottom: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
        padding: 0 10px;
        line-height: 29px;
      }
      
}
// .el-pager li.active {
//   color: @themeColor;
// }
// .el-pager li:hover {
//   color: @themeColor;
// }
// .el-pagination .btn-prev:hover {
//   color: @themeColor;
// }
// .el-pagination .btn-next:hover {
//   color: @themeColor;
// }
// .el-input--suffix:hover .el-input__inner {
//   border: 1px solid @themeColor !important;
// }
</style>
