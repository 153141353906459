export default {
  hoemData: {
    method: "post",
    url: "/home/pcread",
    isLoading:'false'
  },

  // 首页 -弹窗
  getPopup: {
    method: "post",
    url: "/home/popup/read",
  },
  // 首页 -文字
  getHead: {
    method: "post",
    // url: "/web/head/content",
    url: "home/read",
  },
  // 首页 -点击
  getClick: {
    method: "post",
    url: "/adv/djl/save",
  },
  // 首页 - 菜单,广告图,logo,二维码
  getNavInfo: {
    method: "post",
    // url: "/home/index/header",
    url: "/home/common",
  },
  // 首页顶部
  getComHeader: {
    method: "post",
    // url: "/home/index/header",
    url: "/home/header",
    isLoading:'false'
  },
  // 首页底部
  getComBottom: {
    method: "post",
    // url: "/home/index/header",
    url: "/home/bottom",
    isLoading:'false'
  },
  // 首页 - 搜索联想词
  getSearch: {
    method: "post",
    url: "/goods/searchKey",
    isLoading: "false"
  },
  // 首页 - 热门搜索
  getHotkeywords: {
    method: "post",
    url: "/search/hotkeywords/index",
  },
  // 首页 - 商品分类
  getCate: {
    method: "post",
    // url: "/goods/cate/list",
    url: "/goods/cate",
    isLoading:'false'
  },
  // 首页 - 公告
  getMessage: {
    method: "post",
    url: "/message/message/index",
  },
  // 首页 - 秒杀，优惠券
  getActivity: {
    method: "post",
    url: "/home/activity/list",
  },
  // 首页 - 楼层
  getFloor: {
    method: "post",
    url: "/home/floor/list",
  },
  // 首页 - 企业内容
  getArticle: {
    method: "post",
    url: "/home/article/index",
  },
  // 首页 - 经营资质
  getQualifications: {
    method: "post",
    url: "/home/zzxx/index",
  },
  // 首页 - 品牌专区
  getBrand: {
    method: "post",
    url: "/home/brand/view",
  },
  // 首页 - 底部信息
  getFoot: {
    method: "post",
    url: "/home/footer/list",
  },
  // 登录 - 背景图
  getLoginBgi: {
    method: "post",
    url: "/tools/loginbg",
  },
  // 领券中心
  getCoupon: {
    method: "post",
    url: "/activity/coupon",
  },
  // 领券中心
  getCouponItem: {
    method: "post",
    url: "/activity/couponExchange",
  },
  // 首页弹窗广告
  getHomeAdv: {
    method: "post",
    url: "/home/focusadv",
    isLoading:'false'
  },
  // 直播信息
  getliveInfo: {
    method: "post",
    url: "/live/info",
  },
  // 直播商品
  getliveGoods: {
    method: "post",
    url: "/live/goods",
  },
  // 直播历史对话
  getliveDialogs: {
    method: "post",
    url: "/live/dialogs",
  },
  // 直播抢红包
  getliveCoupon: {
    method: "post",
    url: "/live/getRedpack",
  },
  // 添加网址到桌面
  toDesk: {
    method: "post",
    url: "/tools/toDeskpod",
  },
};
