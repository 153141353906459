<template>
  <div class="goods_wrap" @click="handleDetail">
    <div class="border_img">
      <img src="@/assets/img/zone/border.png" alt="" />
    </div>
    <div class="img">
      <img :src="item.logo" alt="" />
    </div>
    <div class="goods_con">
      <p class="price">{{ item.price }}</p>
      <p class="title shenglue_1">{{ item.title }}</p>
      <div class="main">
        <p class="shenglue_1">{{ item.manufacturer }}</p>
        <p>规格：{{ item.sku }}</p>
        <div class="df_box">
          <p>效期：{{ item.validity_date }}</p>
          <p>件装量：{{ item.jzl }}</p>
        </div>
        <div class="df_box">
          <p>单位：{{ item.bzdw }}</p>
          <p>中包装：{{ item.zbz }}</p>
        </div>
        <p>库存：{{ item.kc_str }}</p>
      </div>
      <div class="df_box numbercart" style="margin-top: 10px; z-index: 99999">
        <div class="number_box">
          <div class="bg_img">
            <img src="@/assets/img/zone/line.png" alt="" />
          </div>

          <div class="left" @click.stop="downsl(item)">
            <i class="el-icon-minus"></i>
          </div>
          <div @click.stop="clickls()">
            <el-input class="num numberel" @change="validsl(item.step, item)" v-model="item.step"></el-input>
          </div>
          <div class="right c3" @click.stop="addsl(item)">
            <i class="el-icon-plus"></i>
          </div>
        </div>
        <div class="add" v-if="item.show_cart == 101" @click.stop="addToCart">
          <div class="bg_img">
            <img src="@/assets/img/zone/fill.png" alt="" />
          </div>
          <span>加入购物车</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleDetail() {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id: this.item.id },
      });
      window.open(href, "_blank");
    },
    addToCart() {
      if (this.item.show_cart != 101) return;
      let p = {
        id: this.item.id,
        number: this.item.cart_number,
      };
      this.$api("goods.addCart", p).then(() => {
        // this.$message.success("加入购物车成功");
        this.$store.dispatch("getBadge");
      });
    },
    // collectClick() {
    //   if (this.item.is_collect == 102) {
    //     this.$api("goods.addCollect", { id: this.item.id }).then(() => {
    //       this.$message.success("收藏成功");
    //       this.item.is_collect = 101;
    //     });
    //   } else {
    //     this.$api("account.delCollect", { id: this.item.id }).then(() => {
    //       this.$message.success("取消收藏成功");
    //       this.item.is_collect = 102;
    //     });
    //   }
    // },
    handleMinus() {
      let { show_cart, can_buy, can_buy_str, step, add_sl, cart_number } = this.item;

      if (show_cart == 102) return;
      if (can_buy == 102) {
        this.$message.error(can_buy_str); //101可购买 102禁止购买
        return;
      }

      if (Number(step) >= Number(cart_number)) {
        this.$message.error("不能再减少了");
        return;
      }

      this.item.cart_number = Number(cart_number) - Number(add_sl);
    },
    handlePlus() {
      let { show_cart, can_buy, can_buy_str, add_sl, cart_number, max_number } = this.item;

      if (show_cart == 102) return;
      if (can_buy == 102) {
        this.$message.error(can_buy_str); //101可购买 102禁止购买
        return;
      }
      if (Number(cart_number) >= Number(max_number)) {
        this.$message.error("已达库存上限");
        return;
      }

      this.item.cart_number = Number(cart_number) + Number(add_sl);
    },
    addsl(item) {
      console.log("增加", item);
      let sl = Number(item.cart_number);
      sl = sl + Number(item.add_sl);
      this.validsl(sl, item);
    },
    downsl(item) {
      console.log("减少", item);
      let sl = Number(item.cart_number);
      sl = sl - Number(item.add_sl);
      this.validsl(sl, item);
    },
    validsl(sl, item) {
      console.log(sl, item);
      sl = Number(sl);
      let add_sl = Number(item.add_sl);
      let step = Number(item.step);
      let max_number = Number(item.max_number);
      if (sl <= step) {
        sl = step;
      } else if (sl > max_number) {
        sl = max_number;
      }

      let bs = Math.floor((sl - step) / add_sl);
      sl = bs * add_sl + step;
      this.item.cart_number = sl;
    },

    clickls() {},
  },
};
</script>
<style type="text/css">
.numberel .el-input__inner {
  height: 25px;
  padding: 0px;
  text-align: center;
  line-height: 25px;
  z-index: 10;
  border: 0;
}
</style>

<style lang="less" scoped>
.goods_wrap {
  background-color: #fff;
  width: 230px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 15px 10px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 15px 0px rgba(221, 221, 221, 0.5);
  }
  .border_img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .img {
    width: 100%;
    height: 200px;
    background: #eeeeee;
    border-radius: 4px;
    margin-bottom: 12px;
  }
  .goods_con {
    padding: 0 5px;
    .price {
      font-size: 16px;
      font-weight: bold;
      color: @priceRed;
    }
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
    .main {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.6;
      color: #666666;
    }
    .df_box {
      display: flex;
      justify-content: space-between;
    }
    .numbercart {
      // position:absolute
    }
    .number_box {
      width: 92px;
      height: 27px;
      border-radius: 2px;
      display: flex;
      position: relative;
      .bg_img {
        position: absolute;
      }
      i {
        font-size: 12px;
      }
      .left {
        color: #ddd;
        z-index: 10;
      }
      .left,
      .right {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
      }
      .num {
        width: 36px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #dddddd;
        text-align: center;
        // border-left: 1px solid #dddddd;
        // border-right: 1px solid #dddddd;
        border-radius: 0px;
        z-index: 10;
      }
    }
    .add {
      width: 92px;
      height: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #fefefe;
      position: relative;
      .bg_img {
        position: absolute;
      }
      span {
        position: absolute;
      }
    }
  }
}
</style>
