export default {
  // 积分商城类型
  getPointsType: {
    method: "post",
    url: "/activity/scoreType",
  },
  // 积分商城
  getPoints: {
    method: "post",
    url: "/activity/score",
  },
  // 积分商城-兑换
  ExchangePoints: {
    method: "post",
    url: "/activity/scoreExchange",
  },
  // 活动分类
  getActiveTab: {
    method: "post",
    url: "/activity/centerTop",
    isLoading:'false'
  },
  // 活动列表
  getActivity: {
    method: "post",
    url: "/area/promotion/index",
  },
  // 活动列表数据
  getActiveArr: {
    method: "post",
    url: "/goods/activity",
  },
  // 活动列表-优惠券
  getActivityCoupon: {
    method: "post",
    url: "/area/coupon/index",
  },
  // 活动列表-领取优惠券
  receiveActivityCoupon: {
    method: "post",
    url: "/coupon/exchangeone/save",
  },
  // 品牌专区列表
  getBrand: {
    method: "post",
    url: "/goods/brands",
  },
  // 专区渲染
  getZone: {
    method: "post",
    url: "/home/menu/view",
  },
  // 组合商品列表
  getPackage: {
    method: "post",
    url: "/goods/packages",
  },
  // 组合商品详情
  getPackageDel: {
    method: "post",
    url: "/package/package/read",
  },
  // 中药专区
  getMedicine: {
    method: "post",
    url: "/goods/zy/lc",
  },
  // 专区
  getZoneData: {
    method: "post",
    url: "/activity/zone",
  },
  // 控销专区
  controlData: {
    method: "post",
    url: "/goods/control",
  },
  // 横幅
  toolsBanner: {
    method: "post",
    url: "/tools/banner",
    isLoading:'false'
  },
};
