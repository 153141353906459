<template>
  <div
    class="goods_wrap"
    :class="[
      msFlag ? 'ms-bgc' : '',
      hgFlag ? 'hg-bgc' : '',
      mzFlag ? 'mz-bgc' : '',
      tjFlag ? 'tj-bgc' : '',
      ,
      mjFlag ? 'mj-bgc' : '',
    ]"
    @click="handleDetail"
    @mouseenter="show = true"
    @mouseleave="show = false"
  >
    <div class="mll-box" v-if="item.grossmargin">
      <img :src="mllicon" alt="" />
      <div class="mll-text">{{ item.grossmargin }}</div>
    </div>
    <div class="hide-bottom"></div>
    <div class="img">
      <img :src="item.logo" alt="" />
      <div class="cuxiao flex_c_c" v-if="item.show_memo">
        {{ item.show_memo }}
      </div>
      <div v-if="item.yhq_str" class="qhPrice_box">
        <img src="@/assets/img/index/qhj_img.png" alt="" />
        <p class="qhj_text">券后价</p>
        <p class="qhj_price">{{item.yhq_str}}起</p>
      </div>
    </div>
    <div class="goods_con">
      <!-- <div
        v-if="msFlag || hgFlag || mzFlag || tjFlag || mjFlag"
        :class="['color-hg', msFlag ? 'ms-style' : '', hgFlag ? 'hg-style' : '', mzFlag ? 'mz-style' : '', tjFlag ? 'tj-style' : '', mjFlag ? 'mj-style' : '']"
      >
        <p :class="['price', item.show_cart == 101 ? 'red-price' : 'gray-price']">￥{{ item.price }}</p>
        <p v-if="item.line_price" class="line-price">￥{{ item.line_price }}</p>
      </div> -->
      <div class="red-price">
        <p
          :class="[
            'price',
            item.show_cart == 101 ? 'red-color-price' : 'gray-price',
          ]"
        >
          ￥{{ item.price }}
        </p>
        <p v-if="item.line_price" class="line-price">￥{{ item.line_price }}</p>
      </div>
      <p class="goods-title shenglue_1">{{ item.title }}</p>
      <tagcom :tags="item.tags" style="padding-left: 15px"></tagcom>
      <div class="main">
        <p class="shenglue_1">{{ item.manufacturer }}</p>
        <p class="shenglue_1">规格：{{ item.sku }}</p>
        <div class="df_box">
          <p>
            效期：<span class="yxq-color">{{ item.validity_date }}</span>
          </p>
          <p>件装量：{{ item.jzl }}</p>
        </div>
        <div class="df_box">
          <p>库存：{{ item.kc_str }}</p>
          <p>中包装：{{ item.zbz }}</p>
        </div>
        <p class="text-overflow">单位：{{ item.scqy }}</p>
      </div>
      <div class="df_box" style="margin-top: 10px; padding: 0 15px" @click.stop>
        <div class="number_box">
          <!-- <div class="left" @click.stop="handleMinus">
            <i class="el-icon-minus"></i>
          </div>
          <div class="num">
            <el-input v-model="item.number" @blur="blur(item)"></el-input>
          </div>
          <div class="right c3" @click.stop="handlePlus">
            <i class="el-icon-plus"></i>
          </div> -->
          <el-input-number
            size="mini"
            :disabled="item.show_cart == 102"
            :min="Number(item.min_num)"
            :max="Number(item.max_num)"
            :step="Number(item.step)"
            v-model="item.number"
            @blur="blur(row)"
            @change="changeNum"
          ></el-input-number>
          <!-- <el-input-number size="mini" :min="10" :max="100" :step="1" v-model="number" @blur="blur(row)"></el-input-number> -->
        </div>

        <!-- <div class="add-btn">加入购物车</div> -->
        <div
          class="add"
          :class="[item.show_cart == 101 ? '' : 'gray']"
          @click.stop="addToCart"
        >
          加入购物车
        </div>
      </div>
      <div class="tag_xie">
        <!-- <img src="@/assets/img/goods/tj.png" alt="" /> -->
        <!-- <img src="@/assets/img/goods/jxq.png" alt="" /> -->
      </div>
      <div class="sku" v-if="item.kc_str == 0">
        <img src="@/assets/img/goods/kcbz.png" alt="" />
      </div>
      <div class="kegou" v-if="item.show_red">
        <img src="@/assets/img/goods/bg.png" alt="" />
        <p>{{ item.show_red }}</p>
      </div>
      <div class="collect" v-if="show" @click.stop="collectClick">
        <img
          v-if="item.is_collected == 102"
          src="@/assets/img/goods/coll.png"
          alt=""
        />
        <img
          v-if="item.is_collected == 101"
          src="@/assets/img/goods/iscoll.png"
          alt=""
        />
      </div>
      <div class="tag_mll" v-if="item.grossmargin">
        <img src="@/assets/img/goods/icon_mll.png" alt="" />
        <p class="mll_text">{{ item.grossmargin }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import tagcom from "@/components/tag.vue";
import icon from "@/assets/img/goods/icon.png";
export default {
  components: {
    tagcom,
  },
  // props: {
  //   item: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  props: ["itemData"],
  data() {
    return {
      show: false,
      msFlag: false,
      hgFlag: false,
      mzFlag: false,
      tjFlag: false,
      mjFlag: false,
      mllicon: icon,
      number: 10,
      item: {
        max_num: 0,
        min_num: 1,
        step: 1,
        number: 1,
      },
    };
  },
  watch: {
    itemData: {
      handler(newVal) {
        this.item = newVal;
        // console.log("初始化的商品信息-----", this.item);
        // if (this.item.min_num) {
        //   this.$set(this.item, "number", this.item.min_num);
        //   console.log("初始化的商品数量-----", this.item.number);
        // } else {
        //   this.$set(this.item, "number", 1);
        // }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    // console.log("单条商品信息----", this.item);
    // if (this.item.min_num) {
    //   this.$set(this.item, "number", this.item.min_num);
    // } else {
    //   this.$set(this.item, "number", 1);
    // }

    if (this.item.tags && this.item.tags.length > 0) {
      let hasMS = this.item.tags.filter((v) => {
        return v.title == "秒杀";
      });
      if (hasMS.length > 0) {
        this.msFlag = true;
      }
      let hasHG = this.item.tags.filter((v) => {
        return v.title == "换购";
      });
      if (hasHG.length > 0) {
        this.hgFlag = true;
      }
      let hasMZ = this.item.tags.filter((v) => {
        return v.title == "买赠";
      });
      if (hasMZ.length > 0) {
        this.mzFlag = true;
      }
      let hasTJ = this.item.tags.filter((v) => {
        return v.title == "特价";
      });
      if (hasTJ.length > 0) {
        this.tjFlag = true;
      }
      let hasMJ = this.item.tags.filter((v) => {
        return v.title == "单品满减";
      });
      if (hasMJ.length > 0) {
        this.mjFlag = true;
      }
    }
  },
  mounted() {
    
  },
  methods: {
    handleDetail() {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id: this.item.id },
      });
      window.open(href, "_blank");
    },
    addToCart() {
      if (this.item.show_cart != 101) return;
      let p = {
        id: this.item.id,
        number: this.item.number,
      };
      this.$api("goods.addCart", p).then(() => {
        this.$store.dispatch("getBadge");
      });
    },
    getSocketData(res) {
      let data = res.detail;
      console.log(data);
      if(data.data.code==200){
        this.$message.success(data.data.desc)
      }
    },
    collectClick() {
      console.log();
      if (this.item.is_collected == 102) {
        this.$api("goods.addCollect", { id: this.item.id }).then(() => {
          this.$message.success("收藏成功");
          this.item.is_collected = 101;
        });
      } else {
        this.$api("goods.delCollect", { id: this.item.id }).then(() => {
          this.$message.success("取消收藏成功");
          this.item.is_collected = 102;
        });
      }
    },
    // handleMinus() {
    //   let { show_cart, step, number, min_num } = this.item;
    //   if (show_cart == 102) return;
    //   if (Number(number) <= Number(min_num)) {
    //     this.$message.error("不能再减少了");
    //     return;
    //   }
    //   if (Number(number) - Number(step) <= Number(min_num)) {
    //     this.$message.error("不能再减少了");
    //     return;
    //   }
    //   this.item.number = Number(number) - Number(step);
    //   console.log("减----", min_num, this.item.number);
    // },
    // handlePlus() {
    //   console.log("单条商品数据---", this.item);
    //   let { show_cart, step, max_num, number } = this.item;
    //   if (show_cart == 102) return;
    //   if (Number(number) + Number(step) > Number(max_num)) {
    //     this.item.number = Number(number) + Number(step) - Number(step);
    //     number = Number(number) + Number(step) - Number(step);
    //     this.$message.error("已达购买上限");
    //     return;
    //   } else if (Number(this.item.number) == Number(max_num)) {
    //     this.item.number = Number(max_num);
    //   } else {
    //     this.item.number = Number(number) + Number(step);
    //   }
    //   console.log("222----", max_num, this.item.number);
    // },
    blur(item) {
      console.log(item);
      if (Number(this.item.number) >= Number(this.item.max_num)) {
        this.$message.error(`该商品最大购买量为${this.item.max_num}`);
        this.item.number =
          Math.floor(Number(this.item.max_num) / Number(this.item.step)) *
          Number(this.item.step);
      }
      if (Number(this.item.number) < Number(this.item.min_num)) {
        this.$message.error(`该商品最小购买量为${this.item.min_num}`);
        this.item.number = Number(this.item.min_num);
      }
      if (Number(this.item.number) % 1 !== 0) {
        let num = Math.ceil(Number(this.item.number));
        if (num > Number(this.item.max_num)) {
          this.$message.error(`该商品最大购买量为${this.item.max_num}`);
          this.item.number = Number(this.item.max_num);
        } else {
          this.item.number = num;
        }
      }
    },
    // 计算毛利
    setProfit: function (item) {
      if (!item.retail_price || !item.price) return "";
      let price = (item.retail_price - item.price) / item.retail_price;
      if (price > 0) {
        return Math.floor(price * 100) + "%";
      } else {
        return "";
      }
    },
    changeNum(e) {
      console.log("------", e);
    },
  },
};
</script>

<style lang="less" scoped>
.goods_wrap {
  background-color: #fff;
  width: 230px;
  border: 1px solid #dddddd;
  // border: 1px solid red;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 10px 0;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
  // box-shadow: inset 0 0 10px #d20817;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 8px 0px #d1c2c2;
  }
  .hide-bottom {
    width: 100%;
    height: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #fff;
  }
  .mll-box {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 89px;
    height: 76px;
    z-index: 10;
    .mll-text {
      position: absolute;
      top: 48px;
      left: 5px;
      width: 70px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
    }
  }

  .img {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    background: #eeeeee;
    border-radius: 4px;
    margin-bottom: 2px;
    position: relative;
    .qhPrice_box {
      position: absolute;
      width: 100px;
      height: 75px;
      bottom: 0px;
      left: -14px;
      > p {
        position: absolute;
        left: 0;
        text-align: center;
        white-space: nowrap;
        color: #fff;
      }
      .qhj_text {
        width: 62px;
        top: 32px;
        font-size: 13px;
      }
      .qhj_price {
        width: 62px;
        top: 51px;
        font-size: 15px;
      }
    }

    .cuxiao {
      position: absolute;
      left: -10px;
      bottom: 0;
      width: 230px;
      padding: 3px 10px;
      box-sizing: border-box;
      background: #000000;
      opacity: 0.65;
      font-size: 12px;
      color: #fff;
    }
  }
  .goods_con {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    // padding: 0 5px;
    .red-price {
      padding: 4px 15px;
      // padding: ;
      display: flex;
      align-items: end;
      .price {
        font-size: 16px;
        font-weight: bold;
      }
      .red-color-price {
        color: red;
      }
      .gray-price {
        color: #333;
      }
      .line-price {
        color: #999;
        font-size: 13px;
        margin-left: 10px;
        text-decoration: line-through;
      }
      .title {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
    }
    .goods-title {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      margin-left: 15px;
    }
    .color-hd {
      padding: 4px 15px;
      background-color: #f1cb5d;
    }

    .price-box {
      padding: 4px 15px;
      color: #333333;
      // color: #faecd2;
      // background-color: #d20817;
      .price {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        // color: @priceRed;
        // color: #faecd2;
      }
      .gray-price {
        font-size: 16px;
        font-weight: bold;
        color: #6e6d6c;
      }
      .title {
        font-size: 14px;
        font-weight: bold;
        // color: #333333;
        // color: #faecd2;
      }
    }
    .nomal-style {
      color: red;
      font-size: 16px;
      font-weight: bold;
    }
    // .price {
    //   color: #fff;
    //   font-size: 16px;
    //   font-weight: bold;
    // }
    .color-hg {
      padding: 4px 15px;
      color: #333333;
      display: flex;
      align-items: end;
      // color: #faecd2;
      // background-color: #d20817;
      .price {
        font-size: 16px;
        font-weight: bold;
        // color: red;
        // color: @priceRed;
        // color: #faecd2;
      }
      .line-price {
        color: #fff;
        font-size: 13px;
        margin-left: 10px;
        text-decoration: line-through;
      }
      .title {
        font-size: 14px;
        font-weight: bold;
        // color: #333333;
        // color: #faecd2;
      }
    }
    // 颜色分类

    // .ms-style {
    //   color: #ffffff;
    //   background-color: #d20817;
    // }
    // .hg-style {
    //   color: #ffffff;
    //   background-color: #eb8720;
    // }
    // .mz-style {
    //   color: #ffffff;
    //   background-color: #0abc64;
    // }
    // .tj-style {
    //   color: #ffffff;
    //   background-color: #f84605;
    // }
    // .mj-style {
    //   color: #ffffff;
    //   background-color: #acbf21;
    // }

    .main {
      padding: 0 15px;
      margin-top: 30px;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.6;
      color: #666666;
      .text-overflow {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .df_box {
      display: flex;
      justify-content: space-between;
      .yxq-color {
        color: #f18025;
      }
    }
    .number_box {
      width: 91px;
      height: 26px;
      // border: 1px solid #dddddd;
      border-radius: 2px;
      display: flex;
      .left {
        color: #ddd;
      }
      .left,
      .right {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .num {
        width: 36px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        ::v-deep .el-input__inner {
          padding: 0 4px;
          height: 26px;
          border: none;
          text-align: center;
        }
      }
    }
    .add-btn {
      width: 80px;
      height: 25px;
      line-height: 25px;
      background-color: #41c9eb;
      text-align: center;
      font-size: 12px;
      color: #fefefe;
    }
    .add {
      width: 80px;
      // padding: 4px 0;
      height: 25px;
      line-height: 26px !important;
      background: @themeColor;
      border-radius: 13px;
      text-align: center;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      font-size: 12px;
      // font-weight: 400;
      color: #fefefe;
      &.gray {
        background: #dddddd;
        color: #999;
      }
    }

    .tag_xie {
      top: 0;
      right: 0;
      position: absolute;
      width: 70px;
      height: 70px;
    }
    .sku {
      width: 100px;
      height: 92px;
      position: absolute;
      top: 65px;
      left: 65px;
    }
    .collect {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .kegou {
      top: 0;
      left: 0;
      position: absolute;
      width: 110px;
      height: 30px;
      p {
        font-size: 12px;
        position: absolute;
        top: 6px;
        left: 10px;
      }
    }
  }
  .tag_mll {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 68px;
    .mll_text {
      position: absolute;
      top: 44px;
      left: 5px;
      width: 67px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
    }
  }
}
// .ms-bgc {
//   box-shadow: inset 0 0 10px #d20817;
// }
// .hg-bgc {
//   box-shadow: inset 0 0 10px #eb8720;
// }
// .mz-bgc {
//   box-shadow: inset 0 0 10px #0abc64;
// }
// .tj-bgc {
//   box-shadow: inset 0 0 10px #f84605;
// }
// .mj-bgc {
//   box-shadow: inset 0 0 10px #acbf21;
// }
</style>
