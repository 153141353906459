import axios from "axios";
import cfg from "@/config";
import { Message, Loading } from "element-ui";
import { LgetItem, LreItem } from "../utils/storage";
let loadingInstance;

axios.defaults.baseURL = cfg.baseUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.get.Accept = "application/json";
axios.defaults.timeout = 20000;

// 请求拦截
axios.interceptors.request.use(
  (config) => {
    if(config.isLoading !== 'false'){
      loadingInstance = Loading.service({ fullscreen: true });
    }
    config.headers.platform = "pc";
    if (LgetItem("token")) {
      config.headers.token = LgetItem("token");
    }
    // if (localStorage.getItem("token")) {
    //   config.headers.token = localStorage.getItem("token");
    // }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 响应拦截
axios.interceptors.response.use(
  (res) => {
    if(loadingInstance){
      loadingInstance.close();
    }
    if (res.data.code == 200) {
      return res.data;
    } else if (res.data.code == 202) {
      LreItem("token");
      LreItem("info");
      this.$router.replace("/login");
    } else if (res.data.code == 203) {
      Message.error("您已在别处登录");
      return Promise.reject(res.data);
    } else if (res.data.code == 201) {
      Message.error(res.data.desc);
      return res.data;
      // return Promise.reject(res.data);
    } else {
      Message.error(res.data.desc);
      return Promise.reject(res.data);
    }
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default function (options) {
  let params = {
    method: options.method,
    url: options.url,
    isLoading: options.isLoading,
    data: options.params,
    ...options.other,
  };
  if (params.method.toLowerCase() === "get") {
    params.params = params.data;
    delete params.data;
  }
  return axios(params);
}
