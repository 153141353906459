<template>
  <div>
    <div class="flex_row">
      <div class="info_wrap">
        <div class="imgbox">
          <div class="img">
            <img :src="header_logo" />
          </div>
          <p class="color_theme" @click="$router.push('/index/subRouter/multiStore')">切换账号</p>
          <p class="c6 mt_10">
            当前账号：<span>{{ info.name }}</span>
          </p>
          <p class="com">{{ info.organization_name }}<img class="vipIcon" :src="info.level.logo" alt=""> </p>
        </div>
        <div class="flex_1">
          <div class="flex_sp amount_box">
            <div class="flex_d_c_c" style="width: 33%">
              <p class="price">{{ info.money }}</p>
              <p class="str">账户余额</p>
              <p class="color_theme" @click="$router.push('/index/subRouter/balance')">去充值</p>
            </div>
            <div class="flex_d_c_c" style="width: 33%">
              <p class="price">{{ info.coupon }}</p>
              <p class="str">优惠券金额</p>
              <p class="color_theme" @click="$router.push('/index/subRouter/coupon')">去领券</p>
            </div>
            <div class="flex_d_c_c" style="width: 33%">
              <p class="price">{{ info.points }}</p>
              <p class="str">积分余额</p>
              <p class="color_theme" @click="$router.push('/index/subRouter/integral')">去兑换</p>
            </div>
          </div>

          <div class="kefu">
            <div class="">
              <p>
                专属客服：<span>{{ customer.name }}</span>
              </p>
              <p>
                QQ号码：<span>{{ customer.qq }}</span>
              </p>
            </div>
            <div class="">
              <p>
                联系电话：<span>{{ customer.phone }}</span>
              </p>

              <p>
                微信号码：<span>{{ customer.wechat }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="border"></div>
      <div class="right flex_column">
        <div v-for="item in list" :key="item.key" class="flex_sp">
          <p>{{ item.title }}</p>
          <p>
            有效期至：<span>{{ item.date }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="hor_bor"></div>
    <div class="order_wrap">
      <div class="flex_sp">
        <div class="flex_row_aic">
          <div class="compont_p">我的订单</div>
          <div class="flex_row item_wrap">
            <div @click="choose(1)" :class="current == 1 ? 'active' : ''">
              待付款 <span>{{ info.order1_num }}</span>
            </div>
            <div @click="choose(2)" :class="current == 2 ? 'active' : ''">
              待发货 <span>{{ info.order2_num }}</span>
            </div>
            <div @click="choose(3)" :class="current == 3 ? 'active' : ''">
              待收货 <span>{{ info.order3_num }}</span>
            </div>
            <div @click="choose(4)" :class="current == 4 ? 'active' : ''">
              已完成 <span>{{ info.order4_num }}</span>
            </div>
            <div @click="choose(5)" :class="current == 5 ? 'active' : ''">
              已取消 <span>{{ info.order5_num }}</span>
            </div>
            <div @click="choose(6)" :class="current == 6 ? 'active' : ''">
              退货退款 <span>{{ info.return_num }}</span>
            </div>
          </div>
        </div>
        <div class="c9 c_p to-order" @click="$router.push('/index/subRouter/order')">
          查看所有订单
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>

      <el-table :data="tableData" class="mt_20">
        <el-table-column prop="orderno" label="订单编号" align="center" />
        <el-table-column prop="total_price" label="订单金额" align="center" />
        <el-table-column prop="order_time" label="订单时间" align="center" />
        <el-table-column prop="status_str" label="订单状态" align="center" />
        <el-table-column prop="name" label="物流" align="center" />
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="flex_row">
              <div class="btn flex_c_c" @click="buyTwices(scope.row)">再次购买</div>
              <div class="btn btn1 flex_c_c c_p" @click="handleClick(scope.row)">订单详情</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="empty" v-else>
        <el-empty :image-size="200"></el-empty>
      </div> -->
      <pagination :total="total" :currentPage="page" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
    </div>
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";
import header_img from "@/assets/img/home/header_img.png";
export default {
  components: {
    pagination,
  },
  data() {
    return {
      header_logo: header_img,
      current: 1,
      info: {},
      customer: {},
      list: [],
      tableData: [],
      total: 0,
      page: 1,
    };
  },
  created() {
    this.$api("account.getMyInfo").then((res) => {
      this.info = res.data;
    });
    this.$api("account.getCustomer").then((res) => {
      this.customer = res.data;
    });
    this.$api("account.getCertificate").then((res) => {
      this.list = res.data;
    });
    this.getOrder();
  },
  methods: {
    choose(i) {
      this.current = i;
      this.page = 1;
      this.getOrder();
    },
    handleClick(row) {
      console.log("订单编号---", row.id, row.orderno);
      this.$router.push({
        path: "/index/subRouter/orderDetail",
        query: { id: row.id, orderno: row.orderno },
      });
    },
    applayAfterSale(row) {
      console.log(row);
    },
    cancleOrder(row) {
      this.$alert("确定取消订单吗？", "取消订单", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.$api("account.cancelOrder", { orderno: row.orderno, id: row.id }).then(() => {
            this.$message.success("取消成功");
            this.getOrder();
          });
          this.$message({
            type: "info",
            message: `action: ${action}`,
          });
        },
      });
    },
    buyTwices(row) {
      console.log(row);
      this.$api("account.buyTwice", { order_id: row.orderno }).then((res) => {
        if (res.code == 200) {
          this.$message.success("已加入购物车");
          this.$store.dispatch("getBadge");
        }
      });
    },
    getOrder() {
      this.$api("account.getOrder", {
        status: this.current,
        page: this.page,
        page_size: 10,
      }).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.count;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getOrder();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getOrder();
    },
  },
};
</script>

<style lang="less" scoped>
.info_wrap {
  width: 690px;
  background: #fff;
  border-radius: 4px;
  padding-right: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  .color_theme {
    color: @themeColor;
    cursor: pointer;
  }
  .imgbox {
    padding-top: 30px;
    width: 250px;
    height: 100%;
    border-right: 2px dashed #eee;
    margin-right: 20px;
    margin-left: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    .img {
      width: 100px;
      height: 100px;
      border-radius: 4px;
      margin-bottom: 20px;
      // background-color: cadetblue;
      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  .amount_box {
    padding: 30px 0;
    border-bottom: 2px dashed #eee;
  }
  .custombtn {
    width: 100px;
    height: 36px;
    font-size: 14px;
  }
  .custombtn1 {
    width: 85px;
    height: 36px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .com {
    font-size: 15px;
    margin-top: 10px;
    color: @themeColor;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    .vipIcon{
      width: 90px !important;
    }
  }

  .price {
    font-size: 24px;
    color: @priceRed;
    line-height: 1.9;
  }
  .str {
    font-size: 16px;
    color: #333;
    line-height: 2;
    margin-bottom: 8px;
  }
  .kefu {
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 2;
    color: #333333;
    display: flex;
    justify-content: space-between;
    >div{
      width: 50%;
    }
    span {
      color: @priceRed;
    }
  }
}
.border {
  // height: 320px;
  width: 10px;
  background-color: #f5f6fa;
  margin-top: -20px;
}
.right {
  flex: 1;
  background-color: #fff;
  padding-left: 10px;
  padding-top: 10px;
  font-size: 12px;
  line-height: 2;
  p {
    color: #333;
    span {
      color: @themeColor;
    }
  }
}
.hor_bor {
  width: 990px;
  margin: 0 -20px;
  height: 10px;
  background-color: #f5f6fa;
}

.order_wrap {
  padding-top: 20px;
  .compont_p {
    border: none;
    padding-bottom: 0;
    margin-right: 50px;
  }
  .item_wrap {
    color: #333;
    div {
      margin-right: 55px;
      cursor: pointer;
      &.active {
        color: @themeColor;
        font-weight: bold;
      }
    }
    span {
      color: @themeColor;
    }
  }
  .btn {
    width: 67px;
    height: 27px;
    border: 1px solid @themeColor;
    border-radius: 4px;
    color: @themeColor;
    cursor: pointer;
  }
  .btn1 {
    margin-left: 10px;
    border: 1px solid #999999;
    color: #333333;
  }
  .to-order {
    color: @themeColor;
  }
}
</style>
