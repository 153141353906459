<template>
  <div class="check_container" id="check_container">
    <div class="content">
      <div class="top flex_sp_c">
        <div>
          <span class="c3">核对订单信息</span>
          <span class="orange"
            >【为了保证货品数量，请在2小时内进行订单支付】</span
          >
        </div>
        <div class="flex_row c9 step">
          <div class="cart_item">
            <span class="color_theme">我的购物车</span>
            <div class="green">
              <span>1</span>
              <div class="line active_bg"></div>
            </div>
          </div>
          <div class="cart_item">
            <span class="color_theme">核对订单信息</span>
            <div class="green">
              <div class="line1 active_bg"></div>
              <span>2</span>
              <div class="line active_bg"></div>
            </div>
          </div>
          <div class="cart_item">
            <span>成功提交订单</span>
            <div class="grey">
              <div class="line1"></div>
              <span>3</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 新地址选择样式 -->
      <div class="big_box">
        <p class="title">
          收货地址信息
          <el-tooltip
            class="item"
            effect="dark"
            content="如果地址有误或需要修改地址请先联系客服"
            placement="top-start"
          >
            <span class="shdz"
              ><i class="el-icon-info"></i> 如何修改收货地址？</span
            >
          </el-tooltip>
        </p>
        <div class="card">
          <div class="dzxx">
            <p><span class="grey">收货人：</span>{{ addressList.cname }}</p>
            <p><span class="grey">联系方式：</span>{{ addressList.lxdh }}</p>
            <p>
              <span class="grey">收货地址：</span
              >{{ addressList.area_str + addressList.address }}
            </p>
            <!-- <div class="tberp">同步证件地址</div> -->
          </div>
        </div>
        <p class="title">商品运费</p>
        <div class="card">
          <div class="dzxx">
            <p>
              实际运费：<span class="colRed">￥{{ info.freight_amount }}</span>
            </p>
            <p>
              订单满<span class="colRed">&nbsp;{{ info.by_amount }}&nbsp;</span
              >元免运费
            </p>
            <p class="colRed">
              所有货物请在送货员在场时开箱验货再签收，如有破损及时拍照（破损货物/快递面单/货物外箱）并联系客服人员，如未当面开箱验货，破损不予赔付，自行承担
            </p>
          </div>
        </div>
        <p class="title">付款方式</p>
        <div class="card">
          <p
            class="tags"
            v-for="item in zffsList"
            :key="item.zffs"
            @click="switchZffs(item)"
            :class="{ 'tags-a': form.zffs === item.zffs }"
          >
            {{ item.title }}
          </p>
          <div v-if="form.zffs === 1" class="card-zxzf">
            支持
            <template v-for="(item, i) in selectedZffs.list"
              ><div :key="i"><img :src="item.logo" alt="" /></div>
              {{ item.title }}</template
            >
            <div><img src="@/assets/img/cart/zfye.png" alt="" /></div>
            余额支付
          </div>
          <div v-else-if="form.zffs === 2" class="card-dgzz">
            <p>
              <span class="justify">账户</span>：{{ selectedZffs.list[0].bank_name }}
            </p>
            <p><span class="justify">开户行</span>：{{ selectedZffs.list[0].bank }}</p>
            <p>
              <span class="justify">账号</span>：<span class="col198">{{
                selectedZffs.list[0].bank_code
              }}</span>
            </p>
          </div>
          <div v-else-if="form.zffs === 3" class="card-zxzf">{{selectedZffs.list[0].title}}</div>
        </div>
        <p class="title">商品清单</p>
        <div class="card">
          <div class="table">
            <el-table :data="info.goods" style="width: 100%">
              <el-table-column prop="title" label="商品" width="280">
              </el-table-column>
              <el-table-column prop="specification" label="规格" width="180">
              </el-table-column>
              <el-table-column prop="manufacturer" label="厂家" width="250">
              </el-table-column>
              <el-table-column prop="validity_date" label="效期" width="120">
              </el-table-column>
              <el-table-column prop="price" label="会员价" width="100">
                <template slot-scope="scope">
                  <span style="color: red"> ￥{{ scope.row.price }} </span>
                </template>
              </el-table-column>
              <el-table-column prop="number" label="数量" width="80">
              </el-table-column>
              <el-table-column prop="total_price" label="合计">
              </el-table-column>
            </el-table>
          </div>
          <div class="details">
            <div>
              <p>
                可计优惠金额：
                <span class="colRed">{{ info.yhq_amount }}</span>
              </p>
              <p>
                满减金额：
                <span class="colRed">{{ info.discount_amount }}</span>
              </p>
              <p>
                优惠金额： <span class="colRed">{{ info.coupon_amount }}</span>
              </p>
              <p>
                商品运费：
                <span class="colRed">{{ info.freight_amount }}</span>
              </p>
              <p>
                应付总额：
                <span class="colRed font_b f16" v-if="form.use_balance == '101'"
                  >￥{{ NumberSub(payMoney, info.user_balance) }}</span
                >
                <span class="colRed font_b f16" v-else>￥{{ payMoney }}</span>
                <span v-if="form.use_balance == '101'"
                  >(余额抵扣：￥{{
                    Number(info.user_balance) > payMoney
                      ? payMoney
                      : info.user_balance
                  }})</span
                >
              </p>
            </div>
          </div>
          <!-- <div class="use">
            <div class="usebox">
              <span>可用优惠券：</span>
              <el-select v-model="form.coupon_id" placeholder="请选择" no-data-text="无可用优惠券" @change="handleCoupon">
                <el-option v-for="item in couponList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
              </el-select>
            </div>
          </div> -->
          <div class="use">
            <div>
              <span>使用余额</span>：
              <el-select
                size="small"
                v-model="form.use_balance"
                placeholder="请选择"
              >
                <el-option
                  :label="'使用余额 ' + '(剩余:' + info.user_balance + ')'"
                  value="101"
                >
                </el-option>
                <el-option label="不使用余额" value="102"> </el-option>
              </el-select>
            </div>
          </div>
          <div v-if="info.show_giftbtn == 101">
            <div class="gift-note-box">
              当前订单可选赠品为{{ info.gift_limit }}元,当前已选择{{
                selectedList.length
              }}个，共计{{ formatAmount(giftsPrice) }}元
            </div>
            <!-- v-if="info.show_giftbtn == 101" -->
            <el-button type="primary" @click="openGifts">可选赠品</el-button>
          </div>
          <!-- edit -->
          <!-- <div class="use use2">
            <div>
              <span>使用优惠券</span>：
              <el-select size="small" v-model="isYhq" placeholder="请选择">
                <el-option label="使用" value="101"> </el-option>
                <el-option label="不使用" value="102"> </el-option>
              </el-select>
            </div>
          </div> -->
        </div>
        <div class="card-box">
          <div class="card-small">
            <p class="title">配送方式</p>
            <div class="card card-flex">
              <span class="grey">配送方式：</span>
              <span style="flex: 1">默认京东物流</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="配送方式需由客服人员修改，如需修改请联系客服"
                placement="top"
              >
                <span class="grey pointer">如何修改配送方式？</span>
              </el-tooltip>
            </div>
          </div>
          <div class="card-small">
            <p class="title">订单备注</p>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="若有特殊要求请填写"
              v-model="form.bz"
              style="display: inline-block"
            >
            </el-input>
          </div>
        </div>
      </div>
      <div class="footerbox">
        <div>
          应付合计：
          <span class="heji" v-if="form.use_balance == '101'"
            >￥{{ NumberSub(payMoney, info.user_balance) }}</span
          >
          <span class="heji" v-else>￥{{ payMoney }}</span>
          <span class="yhje"
            >（优惠金额：￥{{ info.coupon_amount }}
            <span v-if="form.use_balance == '101'"
              >，余额抵扣：￥{{
                Number(info.user_balance) > payMoney
                  ? payMoney
                  : info.user_balance
              }}</span
            >
            ）</span
          >
        </div>
        <p class="btns" @click="handleSubmit()">提交订单</p>
      </div>
    </div>

    <el-dialog title="赠品选择" :visible.sync="dialogVisible" width="65%">
      <!-- <span>选择赠品</span> -->
      <div class="gift-note-box">
        当前订单可选赠品为{{ info.gift_limit }}元,当前已选择{{
          selectedList.length
        }}个，共计{{ formatAmount(giftsPrice) }}元
      </div>
      <div class="gift-pop">
        <el-table
          :data="giftGoods"
          ref="multipleTable"
          :cell-style="tableRowStyle"
          @select="select"
          @select-all="selectAll"
        >
          <el-table-column
            type="selection"
            :selectable="selectable"
            width="55"
            align="center"
          />
          <el-table-column
            prop="date"
            label="赠品信息"
            align="left"
            min-width="180"
          >
            <template slot-scope="{ row }">
              <div class="flex_row_aic" style="color: #606266">
                <img style="width: 87px; height: 87px" :src="row.logo" alt="" />
                <div class="info">
                  <p class="shenglue_1 bold">{{ row.title }}</p>
                  <p>{{ row.scqy }}</p>
                  <p>{{ row.sku }}</p>
                  <p>{{ row.validity_date }}</p>
                  <tagcom :tags="row.tags" :poi="'bottom'"></tagcom>
                </div>
              </div>
              <div class="color_theme">{{ row.show_memo }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="line_price" label="原价" align="center" />
          <el-table-column label="支付金额" align="center">
            <template slot-scope="{ row }">
              <span class="f14 mr_10">{{ row.price }}</span>
              <!-- <span class="f12" style="text-decoration: line-through">{{ row.line_price }} </span> -->
            </template>
          </el-table-column>
          <el-table-column label="数量" align="center" width="150">
            <template slot-scope="{ row }">
              <el-input-number
                size="mini"
                :max="Number(row.max_num)"
                :min="0"
                :step="Number(row.step)"
                v-model="row.number"
                @change="handleChange($event, row)"
                @blur="blur(row)"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="原价小计" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.can_chose == 101" class="f16">
                ￥{{ (Number(row.number) * Number(row.line_price)).toFixed(2) }}
              </span>
              <span v-else class="f16"> -- </span>
            </template>
          </el-table-column>
          <el-table-column label="合计" align="center">
            <template slot-scope="{ row }">
              <span
                v-if="row.can_chose == 101"
                class="f16"
                style="color: #ff4c4c"
              >
                ￥{{ (Number(row.number) * Number(row.price)).toFixed(2) }}
              </span>
              <span v-else class="f16"> -- </span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <span class="c_p" @click="handleDelete(row.id)">删除</span>
            <span class="c_p ml_20" @click="handleCollect(row.id)">加入收藏</span>
          </template>
        </el-table-column> -->
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancleGifts">取 消</el-button>
        <el-button type="primary" @click="confirmGifts">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 支付弹窗 -->
    <el-dialog
      :title="payTitle"
      class="pay-pop"
      :visible.sync="payFlay"
      @close="closePop"
      width="40%"
    >
      <div class="pay-money">{{ paySubTitle }}</div>
      <img style="width: 200px; height: 200px" :src="payQRcode" alt="" />
    </el-dialog>
  </div>
</template>

<script>
// import { handleStr } from "@/utils/tool";
import { NumberSub } from "@/utils/calc";
import { formatAmount } from "@/utils";
// import request from "@/api/request";
export default {
  data() {
    return {
      isDel: true,
      isBack: false,
      show: false,
      balanceFlag: false,
      payTitle: "支付信息",
      paySubTitle: "支付信息",
      payFlay: false,
      payQRcode: "",
      form: {
        address_id: "", //地址id
        // deliverymethod_id: "1", //配送方式id
        // coupon_id: "", //优惠券id
        // preorder_id: this.$route.params.id,
        ddbh: this.$route.params.ddbh,
        use_balance: "101",
        bz: "",
        gift: [], //选择的赠品
        zffs: 1,
      },
      dialogVisible: false,
      info: {},
      giftGoods: [], //赠品列表
      selectedList: [], //选中的赠品
      confirmGiftList: [], //最终赠品
      giftsPrice: 0, //选择的赠品总价
      giftsPayPrice: 0, //选择的赠品需要支付的金额
      addressList: [],
      deliverymethod: [],
      invoice: [],
      qualification: [],
      couponList: [],
      // payList: [],
      freightInfo: {},
      str1: "",
      str2: "",
      str3: "",
      self_address: false, //是否自提
      rules: {
        deliverymethod_id: [
          { required: true, message: "选择配送方式", trigger: "change" },
        ],
        payTypeId: [
          { required: true, message: "选择支付方式", trigger: "change" },
        ],
      },

      zffsList: [], //在线支付列表
      selectedZffs: {}, // 选中的支付方式
    };
  },
  computed: {
    currentAddress() {
      return this.addressList.filter((e) => e.id == this.form.address_id);
    },
    payMoney() {
      let num =
        Number(this.info.total_amount) -
        Number(this.info.discount_amount || 0) -
        Number(this.info.coupon_amount) +
        Number(this.freightInfo.price || 0) +
        Number(this.giftsPayPrice || 0) +
        Number(this.info.freight_amount || 0);
      return (Math.round(num * 100) / 100).toFixed(2);
    },
    //goods_total_price -order_yh_money-优惠券+freightInfo.price
  },
  created() {
    if (!this.$route.params.ddbh) {
      this.$router.push("/index/cart");
      this.$alert("请重新下单", "提示", {
        confirmButtonText: "确定",
        callback: () => {},
      });
      return;
    }
    let id = this.$route.params.ddbh;
    this.$api("cart.getPreorder", { ddbh: id }).then((res) => {
      this.info = res.data;
      // console.log("是否有赠品----", res.data.show_giftbtn);
      if (res.data.show_giftbtn == 101) {
        this.getGifts();
      }
      // this.str1 = handleStr(res.data.hb_title);
      // this.str2 = handleStr(res.data.mj_title);
      // this.str3 = handleStr(res.data.zk_title);
    });
    // 获取付款方式
    this.getDeliverymethod();
    // 选择地址
    this.$api("cart.getAddress", { ddbh: id }).then((res) => {
      if (res.code == 200) {
        let address = res.data.filter((e) => e.is_default == "101")[0];
        if (address) {
          this.addressList = address;
          this.form.address_id = this.addressList.id;
        } else if (res.data.length > 0) {
          this.form.address_id = this.addressList.id;
        } else {
          this.addressList = {
            cname: "",
            lxdh: "",
            area_str: "",
            address: "",
          };
          this.$message.error("请先联系客服添加收货地址");
        }
        console.log(this.addressList);
      }
    });
    // 发票类型
    // this.$api("cart.getInvoice").then((res) => {
    //   this.invoice = res.data;
    // });
    // 随货资质
    // this.$api("cart.getQualification").then((res) => {
    //   this.qualification = res.data;
    // });
    // 优惠券
    // this.$api("cart.getCoupon", { ddbh: id, is_available: "101" }).then(
    //   (res) => {
    //     this.couponList = res.data;
    //     let defaultCoupon = this.couponList.filter((item) => {
    //       return item.is_chosed == 101;
    //     });
    //     if (defaultCoupon.length > 0) {
    //       this.form.coupon_id = defaultCoupon[0].id;
    //     }
    //   }
    // );
    // 支付方式
    // this.$api("cart.getPay", { ddbh: id }).then((res) => {
    //   this.payList = res.data;
    // });
  },
  mounted() {
    window.addEventListener("popstate", this.handleBackButton);
  },
  methods: {
    formatAmount,
    NumberSub,
    handleBackButton() {
      this.isBack = true;
      this.$api("cart.orderDestroy", { ddbh: this.form.ddbh }).then(() => {
        window.removeEventListener("popstate", this.handleBackButton);
      });
    },
    tableRowStyle({ columnIndex }) {
      if (columnIndex == 1 || columnIndex == 3) {
        return "color:#ff4c4c;";
      }
    },
    // 获取付款方式
    getDeliverymethod() {
      this.$api("cart.getDeliverymethod", { platform: "pc" }).then((res) => {
        this.zffsList = res.data;
        this.form.zffs = res.data[0].zffs
        this.selectedZffs = res.data[0]
      });
    },
    // 切换付款方式
    switchZffs(item) {
      this.form.zffs = item.zffs;
      this.selectedZffs = item;
    },
    confirmDelivery(e) {
      let addressAndFreight = this.deliverymethod.list.filter((v) => {
        return v.id == e;
      });
      console.log("选中的配送方式---", addressAndFreight);
      this.freightInfo = addressAndFreight[0];
      if (addressAndFreight[0].need_address == 101) {
        this.show = true;
        this.addressList = this.deliverymethod.address;
      } else {
        this.show = false;
        if (
          this.freightInfo.title == "上门自提" &&
          this.freightInfo.self_address
        ) {
          this.addressList = this.freightInfo;
          this.self_address = true;
        } else {
          this.self_address = false;
        }
      }
    },
    // 是否使用余额
    changeBalance(e) {
      // console.log("是否使用余额---", e);
      this.balanceFlag = e;
      if (e) {
        this.form.use_balance = 101;
      } else {
        this.form.use_balance = 102;
      }
    },
    // getFreight(e) {
    //   if (e == 3) this.show = false;
    //   else this.show = true;
    //   let p = {
    //     preorder_id: this.$route.params.id,
    //     deliverymethod_id: this.form.deliverymethod_id,
    //   };
    //   this.$api("cart.getFreight", p).then((res) => {
    //     this.freightInfo = res.data;
    //   });
    // },
    handleCoupon(e) {},
    getGifts() {
      this.$api("cart.giftGoods", { ddbh: this.$route.params.ddbh }).then(
        (res) => {
          // let data = res.data.filter((v) => {
          //   return v.can_chose == 101;
          // });
          this.giftGoods = res.data.map((v) => {
            // this.$set(v, "number", 1);
            this.$set(v, "sel", 102);
            return v;
          });
          this.giftsPrice = this.selectedList.reduce((sum, item) => {
            return sum + Number(item.line_price) * Number(item.number);
          }, 0);
          this.giftsPayPrice = this.selectedList.reduce((sum, item) => {
            return sum + Number(item.price) * Number(item.number);
          }, 0);
          // console.log("赠品列表----", this.giftGoods);
        }
      );
    },
    selectable(row, index) {
      console.log(index);
      if (row.can_chose == 101) {
        return true;
      } else {
        return false;
      }
    },
    openGifts() {
      // this.$api("cart.giftGoods", { ddbh: this.$route.params.ddbh }).then((res) => {
      //   this.giftGoods = res.data.map((v) => {
      //     this.$set(v, "number", 1);
      //     this.$set(v, "sel", 102);
      //     return v;
      //   });
      // });
      this.selectedList = [];
      this.giftGoods.forEach((e) => {
        if (e.sel == 101) {
          this.selectedList.push(e);
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(e, true);
          });
        }
      });
      // console.log("222赠品列表----", this.giftGoods);
      this.dialogVisible = true;
    },
    handleChange(currentValue, item) {
      console.log("-----", currentValue, item);
      // console.log("选中的有效赠品---", this.selectedList);
      this.giftsPrice = this.selectedList.reduce((sum, item) => {
        return sum + Number(item.line_price) * Number(item.number);
      }, 0);
      this.giftsPayPrice = this.selectedList.reduce((sum, item) => {
        return sum + Number(item.price) * Number(item.number);
      }, 0);
    },
    blur(item) {
      if (item.step != 1) {
        let num = Number(item.number) / Number(item.step);

        if (num % 1 !== 0) {
          item.number = Number(item.step) * (parseInt(num) + 1);
        }
        this.tableData.forEach((e) => {
          if (e.id == item.id) e = item;
        });
        this.giftsPrice = this.selectedList.reduce((sum, item) => {
          return sum + Number(item.line_price) * Number(item.number);
        }, 0);
        this.giftsPayPrice = this.selectedList.reduce((sum, item) => {
          return sum + Number(item.price) * Number(item.number);
        }, 0);
      }
    },
    select(selection, row) {
      // console.log("选中的赠品---", selection, row);
      // this.giftGoods;
      for (let i = 0; i < this.giftGoods.length; i++) {
        if (row.id == this.giftGoods[i].id) {
          this.giftGoods[i].sel = 101;
        }
      }
      this.giftGoods.forEach((item1) => {
        const foundItem = selection.find((item2) => item2.id == item1.id);
        if (foundItem) {
          Object.assign(item1, foundItem);
        } else {
          item1.sel = 102;
          Object.assign(item1, item1);
        }
      });
      // console.log("修改赠品列表---", this.giftGoods);
      // return;
      this.selectedList = selection.filter((v) => {
        return v.can_chose == 101 && v.number > 0;
      });
      this.giftsPrice = this.selectedList.reduce((sum, item) => {
        return sum + Number(item.line_price) * Number(item.number);
      }, 0);
      this.giftsPayPrice = this.selectedList.reduce((sum, item) => {
        return sum + Number(item.price) * Number(item.number);
      }, 0);
    },
    selectAll(selection) {
      this.selectedList = selection;
      this.selectedList = selection.filter((v) => {
        return v.can_chose == 101 && v.number > 0;
      });
      this.giftsPrice = this.selectedList.reduce((sum, item) => {
        return sum + Number(item.line_price) * Number(item.number);
      }, 0);
      this.giftsPayPrice = this.selectedList.reduce((sum, item) => {
        return sum + Number(item.price) * Number(item.number);
      }, 0);
    },
    confirmGifts() {
      this.giftsPrice = this.selectedList.reduce((sum, item) => {
        return sum + Number(item.line_price) * Number(item.number);
      }, 0);
      this.giftsPayPrice = this.selectedList.reduce((sum, item) => {
        return sum + Number(item.price) * Number(item.number);
      }, 0);
      // console.log("选中的有效赠品---", this.selectedList);
      // console.log("选中的赠品总价---", this.giftsPrice);
      if (this.giftsPrice > Number(this.info.gift_limit)) {
        this.$message.warning("选择的赠品总价超过范围");
        return;
      } else {
        this.confirmGiftList = this.selectedList;
      }
      this.form.gift = this.selectedList.map((v) => {
        let obj = {
          id: v.id,
          number: v.number,
        };
        return obj;
      });
      this.dialogVisible = false;
    },
    cancleGifts() {
      this.selectedList = [];
      this.giftsPrice = 0;
      this.dialogVisible = false;
    },
    // 查询支付结果
    getPayStatus(ddbh) {
      this.$api("cart.payStatus", { ddbh: ddbh }).then((res) => {
        if (res.data == 101) {
          this.$confirm("订单支付失败", "提示", {
            confirmButtonText: "关闭",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push({
                path: "/index/subRouter/order",
                query: { index: 1 },
              });
            })
            .catch(() => {
              this.$router.push({
                path: "/index/subRouter/order",
                query: { index: 1 },
              });
            });
        }
        if (res.data == 102) {
          this.$confirm("订单支付失败", "提示", {
            confirmButtonText: "关闭",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push({
                path: "/index/subRouter/order",
                query: { index: 2 },
              });
            })
            .catch(() => {
              this.$router.push({
                path: "/index/subRouter/order",
                query: { index: 2 },
              });
            });
        }
      });
    },
    // 关闭支付弹窗
    closePop() {},
    // 提交订单
    handleSubmit() {
      this.isDel = false;
      if (this.giftsPrice > Number(this.info.gift_limit)) {
        this.$message.warning("选择的赠品总价超过范围");
        return;
      }
      this.$api("cart.submitOrder", this.form).then(() => {
        if (this.form.use_balance == 101) {
          this.$router.push({
            path: "/index/payOrder",
            query: { type: 101, ddbh: this.$route.params.ddbh },
          });
        } else {
          this.$router.push({
            path: "/index/payOrder",
            query: { type: 102, ddbh: this.$route.params.ddbh },
          });
        }

        // this.paySubTitle = res.data.need_pay_desc;
        // if (res.data.need_pay == 101 && this.form.payTypeId == 105) {
        //   let pay_info = this.payList.filter((v) => {
        //     return v.type == this.form.payTypeId;
        //   });
        //   console.log("支付信息---", pay_info);
        //   let url = pay_info[0].url.substr(3, pay_info[0].url.length - 1);
        //   console.log("接口---", url);
        //   const arg = {
        //     url: url,
        //     method: "post",
        //     params: {
        //       ddbh: this.form.ddbh,
        //     },
        //   };
        //   request(arg).then((r) => {
        //     console.log("调用支付信息的接口---", r);
        //     this.payFlay = true;
        //     this.payTitle = r.data.title;
        //     this.payQRcode = r.data.logo;
        //   });
        // }
      });
    },
  },

  destroyed() {
    console.log(
      "========确认订单======22222==================Vue 实例即将销毁"
    );
    window.removeEventListener("popstate", this.handleBackButton);
    if (this.isDel && !this.isBack) {
      console.log("--------------------111------需要调销毁接口");
      this.$api("cart.orderDestroy", { ddbh: this.form.ddbh }).then(() => {});
    } else {
      // console.log("--------------------2222----不需要调销毁接口");
    }
  },
};
</script>

<style lang="less" scoped>
.check_container {
  background-color: #f9f9f9;
  .orange {
    color: #f1622f;
  }
  .top {
    width: 100%;
    height: 74px;
    .c3 {
      font-weight: bold;
    }
    .step {
      width: 360px;
      .cart_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        &:last-child {
          margin: 0;
        }
        .green {
          margin-top: 10px;
          width: 18px;
          height: 18px;
          background: @themeColor;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          position: relative;
        }
        .grey {
          margin-top: 10px;
          width: 18px;
          height: 18px;
          background: #c6c6c6;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          position: relative;
        }
        .line {
          width: 51px;
          height: 3px;
          background-color: #e8e8e8;
          position: absolute;
          left: 18px;
        }
        .line1 {
          width: 51px;
          height: 3px;
          background-color: #e8e8e8;
          position: absolute;
          right: 18px;
        }
        .active_bg {
          background-color: @themeColor;
        }
      }
    }
  }
  .address {
    width: 100%;
    border: 1px solid #dddddd;
    .nav {
      width: 100%;
      height: 36px;
      background: #f3f3f3;
      color: #333;
      padding: 0 15px;
      box-sizing: border-box;
    }
    .main {
      padding: 15px;
      padding-bottom: 0;
      box-sizing: border-box;
      .tip {
        font-size: 12px;
        color: #f1622f;
        i {
          font-size: 14px;
        }
      }
      .info {
        margin-top: 10px;
      }
    }
  }
  .goods {
    width: 100%;
    margin-top: 20px;
    .p1 {
      font-weight: bold;
      color: #333333;
      margin-bottom: 15px;
    }
    .info {
      text-align: left;
      margin-left: 10px;
      width: 100%;
      .tag {
        padding: 1px 6px;
        height: 17px;
        display: flex;
        line-height: 17px;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .red {
    font-size: 14px;
    color: #f93232;
  }
  .freight_info {
    line-height: 2;
    padding: 20px 0;
  }
  .bottom {
    padding: 20px 30px;
    box-sizing: border-box;
    width: 100%;
    background: #ebebeb;
    border-radius: 4px;
    color: #333;
    line-height: 2;
    .float {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .red {
      font-weight: bold;
      color: #f93232;
      font-size: 14px;
    }
  }
  .pay_box {
    display: flex;
    justify-content: flex-end;
  }
  .pay-pop {
    text-align: center;
    padding-bottom: 20px;
    .pay-title {
      width: 100%;
      text-align: center;
      font-size: 20px;
      color: #333;
    }
    .pay-money {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: #53a8ff;
      margin-bottom: 20px;
    }
  }
}
.gift-note-box {
  font-size: 14px;
  color: #f1622f;
  margin-bottom: 10px;
}
.gift-pop {
  width: 90%;
  margin: 0 auto;
}
.hide-table-header /deep/ .el-table__header-wrapper {
  display: none !important;
}
.colRed {
  color: red;
}

.grey {
  color: #999;
}
#check_container {
  position: relative;
  .big_box {
    width: 100%;
    padding: 0 25px 20px;
    box-sizing: border-box;
    background-color: #fff;
    overflow: hidden;
    .title {
      font-size: 20px;
      margin: 40px 0 20px;
      color: #000;
      .shdz {
        font-size: 14px;
        color: #999;
        cursor: pointer;
      }
    }

    .card {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #e4e4e4;
      border-radius: 5px;
      padding: 15px 20px;
      font-size: 14px;

      .dzxx {
        position: relative;
        > p {
          margin: 5px 0;
        }
        .tberp {
          padding: 5px 10px;
          color: #fff;
          position: absolute;
          right: 0px;
          top: 0px;
          background-color: #198c8b;
          cursor: pointer;
          border-radius: 5px;
          &:hover {
            opacity: 0.85;
          }
        }
      }

      .tags {
        display: inline-block;
        width: 90px;
        height: 30px;
        border: 1px solid #f2f2f2;
        text-align: center;
        line-height: 30px;
        color: #666;
        cursor: pointer;
        margin-right: 5px;
      }

      .tags-a {
        color: #3dbb2b;
        border: 1px solid #3dbb2b;
        font-weight: bold;
        // background-color: rgba($color: #a3f099, $alpha: 0.25);
      }

      .card-zxzf {
        margin-top: 15px;
        background-color: #f8f8f8;
        display: flex;
        align-items: center;
        padding: 15px;

        > div {
          width: 19px;
          margin-left: 20px;
          margin-right: 5px;
        }
      }

      .card-dgzz {
        margin-top: 15px;
        padding: 15px;
        background-color: #f8f8f8;

        > p {
          font-size: 15px;
          margin: 5px 0;

          > .justify {
            text-align-last: justify;
            display: inline-block;
            width: 46px;
          }

          .col198 {
            color: #198c8b;
            font-weight: bold;
          }
        }
      }

      .table {
        border: 1px solid #e4e4e4;
        border-bottom: none;

        thead {
          div {
            color: #909399;
          }
        }
      }

      .details {
        display: flex;
        justify-content: flex-end;

        > div {
          width: 300px;
          font-size: 14px;

          > P {
            margin: 5px 0;
          }
        }
      }

      .use {
        display: flex;
        justify-content: flex-end;
        border-top: 2px solid #999;
        padding: 10px 10px;
        .usebox {
          display: flex;
          align-items: center;
          > p {
            width: 220px;
            color: red;
            white-space: nowrap;
          }
        }
      }

      .use2 {
        border-bottom: 2px solid #999;
      }
    }
    .card-small {
      width: 48%;
    }
    .card-flex {
      display: flex;
      font-size: 15px;
      padding: 16px 20px;
    }
    .card-box {
      display: flex;
      justify-content: space-between;
    }
  }
  .footerbox {
    position: sticky;
    bottom: 0px;
    z-index: 999;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    > div {
      font-size: 18px;
      .heji {
        font-size: 22px;
        color: red;
        font-weight: bold;
      }
      .yhje {
        font-size: 15px;
        color: red;
        > span {
          color: red;
        }
      }
    }
    .btns {
      width: 220px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      background-color: #f04844;
      color: #fff;
      cursor: pointer;
      font-size: 18px;
      margin-left: 20px;
    }
  }
}
</style>
